import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { InputAdornment, TextField } from "@mui/material";
import "../SalesManagement/addUser.css";
import Figma_UserIcon from "../../Assets/Images/Figma _UserIcon.png";
import Person3Icon from "@mui/icons-material/Person3";
import PhoneIcon from "@mui/icons-material/Phone";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import CloseIcon from "@mui/icons-material/Close";
import { APIs } from "../../Shared/Config/config";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";

function AddUser({ isOpen, onClose, handleGetRepresentatives, selectedRep }) {
  let getSession = sessionStorage.getItem("X-Session");
  let [userName, setUserName] = useState(selectedRep ? selectedRep.name : "");
  let [mobile, setMobile] = useState(
    selectedRep ? selectedRep.mobileNumber : ""
  );
  let [email, setemail] = useState(selectedRep ? selectedRep.email : "");
  let [errors, setErrors] = useState("");
  let [dialCode, setDialCode] = useState("");
  let [countryCode, setCountryCode] = useState("in");

  console.log(userName);
  console.log(mobile);
  console.log(email);

  useEffect(() => {
    if (selectedRep) {
      setUserName(selectedRep.name);
      setMobile(selectedRep.mobileNumber);
      setemail(selectedRep.email);
    }
  }, [selectedRep]);

  const handleOnChange = (phone, mobileData) => {
    const cd = mobileData.dialCode.length;
    const number = phone.slice(cd);
    setMobile(number);
    setDialCode(mobileData.dialCode);
    setCountryCode(mobileData.countryCode);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "userName":
        setUserName(value);
        break;

      case "mobileNo":
        setMobile(value);
        break;

      case "email":
        setemail(value);
        break;

      default:
        break;
    }
  };

  const validate = () => {
    let tempErrors = {};

    tempErrors.userName = userName ? "" : "Username is required";
    if (!selectedRep) {
      tempErrors.mobileNo = /^[0-9]{10}$/.test(mobile)
        ? ""
        : "Mobile number should be 10 digits";
    }
    tempErrors.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      email
    )
      ? ""
      : "Email is not valid";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((X) => X === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      let payLoad = {
        id: selectedRep ? selectedRep.id : null,
        name: userName,
        countryCode: `+${dialCode}`,
        mobileNumber: mobile,
        email: email,
      };

      if (selectedRep) {
        await handleUpdateRepresentative(payLoad);
      } else {
        await handleAddRepresentative(payLoad);
      }
      setUserName("");
      setMobile("");
      setemail("");
      onClose();
    }
  };

  const handleAddRepresentative = async (payLoad) => {
    let apiurl = APIs.addRepresentative;
    try {
      const res = await axios.post(apiurl, payLoad, {
        headers: {
          "X-Session": getSession,
        },
      });
      handleGetRepresentatives();
      toast.success(`${res.data.data.message}`);
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status < 500
      ) {
        toast.error(`${err.data.error}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleUpdateRepresentative = async () => {
    let requestData = {
      name: userName,
      email: email,
    };
    let apiurl = `${APIs.updateRepresentative}/${selectedRep.id}`;
    console.log("API URL", apiurl);
    try {
      const res = await axios.put(apiurl, requestData, {
        headers: {
          "X-Session": getSession,
        },
      });
      toast.success(`${res.data.data.message}`);
      handleGetRepresentatives();
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status < 500
      ) {
        toast.error(`${err.response.data.error}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  return (
    <Dialog
      disableEnforceFocus
      open={isOpen}
      onClose={onClose}
      disableScrollLock={true}
      PaperProps={{
        style: {
          width: "500px",
          height: "auto",
          alignItems: "center",
          borderRadius: "15px",
          boxShadow: "0px 5px 5px 0px",
        },
      }}
    >
      <div
        style={{ marginLeft: "450px", marginTop: "10px", cursor: "pointer" }}
      >
        <CloseIcon
          onClick={onClose}
          sx={{
            "&:hover": {
              backgroundColor: "ActiveBorder",
              borderRadius: "15px",
              color: "red",
            },
          }}
        />
      </div>
      <DialogTitle style={{ marginTop: "-18px", color: "#01017e" }}>
        <div style={{ display: "flex" }}>
          <div style={{ fontWeight: "bold" }}>
            {selectedRep
              ? "Update Sales Representative Details"
              : "Sales Representative Details"}
          </div>
        </div>
      </DialogTitle>
      <img
        src={Figma_UserIcon}
        alt=""
        style={{ width: "170px", marginTop: "-10px" }}
      />
      <DialogContent>
        <div className="userForm">
          <label>Username</label>
          <div>
            <TextField
              name="userName"
              id="filled-basic"
              // label="User Name"
              variant="outlined"
              // placeholder="User Name"
              required
              error={!!errors.userName}
              helperText={errors.userName}
              value={userName}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person3Icon />
                  </InputAdornment>
                ),
                style: { width: "400px", marginBottom: "15px", height: "50px" },
              }}
            />
          </div>
          <div className="custom-phone-input">
            <TextField
              id="phone-input"
              variant="outlined"
              required
              error={!!errors.mobileNo}
              helperText={errors.mobileNo}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon
                      style={{
                        visibility: "hidden",
                        marginLeft: "-60px",
                        height: "5px",
                        width: "5px",
                      }}
                    />
                  </InputAdornment>
                ),
                inputComponent: PhoneInput,
                inputProps: {
                  name: "mobileNo",
                  value: `${dialCode}${mobile}`,
                  country: countryCode,
                  countryCodeEditable: true,
                  onChange: handleOnChange,
                  disabled: !!selectedRep,
                  inputProps: { maxLength: 19 },
                },
              }}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <div>
              <label>E-mail</label>
            </div>
            <TextField
              name="email"
              id="filled-basic"
              // label="E-mail"
              // placeholder="E-mail"
              required
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email}
              value={email}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MarkEmailReadIcon />
                  </InputAdornment>
                ),
                style: { width: "400px", marginBottom: "8px", height: "50px" },
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          style={{ marginBottom: "20px", marginTop: "10px" }}
        >
          {selectedRep ? "UPDATE" : "SUBMIT"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddUser;
