import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from "@mui/material";
import Sidebar from "../SideBar/sidebar";
import CampaignIcon from "@mui/icons-material/Campaign";
import ReactLanguageSelect from "react-languages-select";
import "react-languages-select/css/react-languages-select.css";
import { APIs } from "../../Shared/Config/config";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./createTemplate.css";

const CreateTemplate = () => {
  const navigate = useNavigate();
  let getSession = sessionStorage.getItem("X-Session");
  let [step, setStep] = useState(1);
  let [select, setSelect] = useState("None");
  // let [addHeaderVariables, setHeaderVariables] = useState("");
  let [showHeaderVariableInput, setShowHeaderVariableInput] = useState(false);
  // let [addBodyVariables, setAddBodyVariables] = useState([]);
  let [showBodyVariableInput, setShowBodyVariableInput] = useState(false);
  let [variableCount, setVariableCount] = useState(1);
  let [bodyVariableCount, setBodyVariableCount] = useState(1);
  let [inputs, setInputs] = useState({
    templateName: "",
    templateLanguage: "en",
    header: "",
    headerVariable: "",
    headerVariableVal: "",
    body: "",
    bodyVariable: [],
    bodyVariableVal: [],
  });

  // let bodyVariableCount = 0;

  const handleContinue = () => {
    setStep(step + 1);
  };
  const handleGoback = () => {
    navigate("/template-management");
  };

  const handleSelect = (event) => {
    setSelect(event.target.value);
  };

  const handleAddVariables = (field) => {
    setShowHeaderVariableInput(true);
    setInputs((prevState) => ({
      ...prevState,
      [field]: prevState[field] + `{{${variableCount}}}`, // Append variable count
    }));
    setVariableCount((prevCount) => prevCount + 1);
  };

  // const handleVariableChange = (event) => {
  //   setHeaderVariables(event.target.value);
  // };

  console.log(inputs);

  // const handleAddBodyVariables = () => {
  //   // setAddBodyVariables((prevVariables) => [...prevVariables, ""]);
  //   setInputs((prevData) => ({
  //     ...prevData,
  //     bodyVariable: [...prevData.bodyVariable, ""],
  //     bodyVariableVal: [...prevData.bodyVariableVal, ""],
  //     body: prevData.body + `{{${bodyVariableCount}}}`
  //   }));
  //   setShowBodyVariableInput(false);
  //   // setInputs((prevState) => ({
  //   //   ...prevState,
  //   //   [field]: prevState[field] + `{{${bodyVariableCount}}}`, // Append variable count
  //   // }));
  //   setBodyVariableCount((prevCount) => prevCount + 1);
  // };

  const handleAddBodyVariables = () => {
    setInputs((prevData) => {
      const bodyVariableArray = Array.isArray(prevData.bodyVariable)
        ? prevData.bodyVariable
        : JSON.parse(prevData.bodyVariable || "[]");

      // Calculate the next available variable number
      const currentVariables = bodyVariableArray.map((v) =>
        parseInt(v.match(/\d+/)[0], 10)
      );

      const maxVariableNumber = Math.max(0, ...currentVariables);
      const newVariableNumber = maxVariableNumber + 1;
      const newVariable = `{{${newVariableNumber}}}`;

      return {
        ...prevData,
        bodyVariable: [...prevData.bodyVariable, newVariable],
        bodyVariableVal: [...prevData.bodyVariableVal, ""],
        body: prevData.body + newVariable,
      };
    });

    setShowBodyVariableInput(false);
    setBodyVariableCount((prevCount) => prevCount + 1);
    console.log(bodyVariableCount);
  };

  const handleBodyVariableChange = (event, index) => {
    const { value } = event.target;
    setInputs((prevInputs) => {
      const updatedBodyVariableVal = [...prevInputs.bodyVariableVal];
      updatedBodyVariableVal[index] = value;
      return {
        ...prevInputs,
        bodyVariableVal: updatedBodyVariableVal,
      };
    });
  };

  // const handleInputs = (e, ind) => {
  //   // const { name, value } = e.target;
  //   const name = e.target.name;
  //   let value = e.target.value;
  //   console.log(value);
  //   if (name === "headerVariable") {
  //     setInputs((prevState) => ({
  //       ...prevState,
  //       [name]: JSON.stringify([value]),
  //       headerVariableVal: value,
  //     }));
  //   } else if (name === "bodyVariable") {
  //     setInputs((prevState) => {
  //       const updatedArray = [...prevState.bodyVariable];
  //       updatedArray[ind] = value;
  //       return {
  //         ...prevState,
  //         bodyVariable: updatedArray,
  //         bodyVariableVal: updatedArray,
  //       };
  //     });
  //   } else {
  //     setInputs((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   }
  // };

  const handleInputs = (e, ind) => {
    const name = e.target.name;
    let value = e.target.value;
  
    if (name === "templateName") {
      value = value.replace(/ /g, "_");
    }
  
    setInputs((prevState) => {
      switch (name) {
        case "headerVariable":
          return {
            ...prevState,
            [name]: JSON.stringify([value]),  // Store as JSON string
            headerVariableVal: value,
          };
  
        case "bodyVariable":
          const updatedArray = [...prevState.bodyVariableVal];
          updatedArray[ind] = value;
  
          const updatedBodyVariable = updatedArray.map((val, index) => `{{${index + 1}}}`);
  
          return {
            ...prevState,
            bodyVariable: updatedBodyVariable,  // Keep as array
            bodyVariableVal: updatedArray,
          };
  
        case "body":
          const uniqueVariables = [...new Set(value.match(/\{\{\d+\}\}/g))];
  
          const updatedBodyVariableVal = uniqueVariables.map((variable) => {
            const currentBodyVariable = prevState.bodyVariable || [];
            const index = currentBodyVariable.indexOf(variable);
            return index !== -1 ? prevState.bodyVariableVal[index] : "";
          });
  
          const updatedBody = value.replace(/\{\{\d+\}\}/g, (match) => {
            const index = uniqueVariables.indexOf(match);
            return `{{${index + 1}}}`;
          });
  
          return {
            ...prevState,
            body: updatedBody,
            bodyVariable: uniqueVariables,  // Keep as array
            bodyVariableVal: updatedBodyVariableVal,
          };
  
        default:
          return {
            ...prevState,
            [name]: value,
          };
      }
    });
  };

  
  // const handleInputs = (e, ind) => {
  //   const name = e.target.name;
  //   let value = e.target.value;

  //   if (name === 'templateName') {
  //     // Replace spaces with underscores for templateName
  //     value = value.replace(/ /g, '_');
  //   }

  //   setInputs((prevState) => {
  //     if (name === "headerVariable") {
  //       return {
  //         ...prevState,
  //         [name]: JSON.stringify([value]),
  //         headerVariableVal: value,
  //       };
  //     } else if (name === "bodyVariable") {
  //       const updatedArray = [...prevState.bodyVariableVal];
  //       updatedArray[ind] = value;

  //       // Generate the updated bodyVariable list based on updatedArray
  //       const filteredBodyVariable = JSON.stringify(updatedArray);

  //       // Update body content with resequenced variable numbers
  //       const updatedBody = prevState.body.replace(/\{\{\d+\}\}/g, (match) => {
  //         const index = prevState.bodyVariable.indexOf(match);
  //         return index !== -1 ? `{{${index + 1}}}` : match;
  //       });

  //       return {
  //         ...prevState,
  //         body: updatedBody,
  //         bodyVariable: JSON.stringify(updatedArray),
  //         bodyVariableVal: updatedArray,
  //       };
  //     } else if (name === "body") {
  //       // Extract unique variables in the body
  //       const uniqueVariables = [...new Set(value.match(/\{\{\d+\}\}/g))];

  //       // Generate updated bodyVariable and bodyVariableVal arrays
  //       const updatedBodyVariable = uniqueVariables;
  //       const updatedBodyVariableVal = updatedBodyVariable.map(variable => {
  //         const index = prevState.bodyVariable.indexOf(variable);
  //         return index !== -1 ? prevState.bodyVariableVal[index] : "";
  //       });

  //       // Update body content with resequenced variable numbers
  //       const updatedBody = value.replace(/\{\{\d+\}\}/g, (match, index) => {
  //         return `{{${updatedBodyVariable.indexOf(match) + 1}}}`;
  //       });

  //       return {
  //         ...prevState,
  //         body: updatedBody,
  //         bodyVariable: updatedBodyVariable,
  //         bodyVariableVal: prevState.bodyVariableVal, // Preserve the previous state of bodyVariableVal
  //       };
  //     } else {
  //       return {
  //         ...prevState,
  //         [name]: value,
  //       };
  //     }
  //   });
  // };

  const CreateTemplate = async () => {
    const { headerVariableVal, bodyVariableVal, ...rest } = inputs;

    // Convert bodyVariableVal to JSON string format similar to headerVariable
    const bodyVariableJSON = JSON.stringify(bodyVariableVal);

    let payLoad = {
      waLanguageCode: "en",
      waHeader: rest.header,
      waHeaderVariables: rest.headerVariable, // Already in JSON string format
      waBody: rest.body,
      waBodyVariables: bodyVariableJSON,  // Send the JSON string of actual values
      templateName: rest.templateName,
      waCategory: "Marketing",
    };

    let apiUrl = APIs.createTemplate;

    await axios
      .post(apiUrl, payLoad, {
        headers: {
          "X-Session": getSession,
        },
      })
      .then((res) => {
        let result = res.data;
        console.log("Result", result);
        toast.success(`${result.data.message}`);
        navigate("/template-management");
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log(err);
          toast.error(`${err.response.data.error.message}`);
        }
      });
  };

  
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#d7d3d3",
            p: 3,
            overflow: "hidden",
            // height: "92vh",
          }}
        >
          <Toolbar />
          <Box
            sx={{
              background: "white",
              height: "80vh",
              overflowY: "auto",
              overflowX: "hidden",
              // marginTop: "20px",
            }}
          >
            {step === 1 && (
               <div className="createTemplate">
               <div className="templateHeader">
                 <h3>Create New Template</h3>
               </div>
               <div className="templateBox">
                 <Box>
                   <div className="header-section">
                     <h4 className="category-title">Category</h4>
                     <div className="button-container">
                       <Button
                         variant="outlined"
                         onClick={handleGoback}
                       >
                         Go back
                       </Button>
                       <Button
                         variant="contained"
                         disabled={!inputs.templateName}
                         onClick={handleContinue}
                       >
                         Continue
                       </Button>
                     </div>
                   </div>
                   <Box className="marketing-box">
                     <div className="marketing-box-content">
                       <CampaignIcon className="campaign-icon" />
                       <div className="text-container">
                         <span className="title">Marketing</span>
                         <div className="description">
                           <span>
                             Promotion or Information about your business, products, or services.
                           </span>
                         </div>
                       </div>
                     </div>
                   </Box>
                   <div className="input-section">
                     <Box className="input-box">
                       <div className="input-group">
                         <div>
                           <span className="input-label">Name</span>
                         </div>
                         <div className="input-description">
                           <span>Name your message template</span>
                         </div>
                         <div className="input-field">
                           <input
                             type="text"
                             placeholder="Enter Template Name"
                             name="templateName"
                             value={inputs.templateName}
                             onChange={(e) => handleInputs(e, null)}
                           />
                         </div>
                       </div>
                       <div className="input-group">
                         <div>
                           <span className="input-label">Language</span>
                         </div>
                         <div className="input-description">
                           <span>Select a language for your template</span>
                         </div>
                         <div className="language-select">
                           <ReactLanguageSelect
                             defaultLanguage="en"
                             searchPlaceholder="Search for a language"
                             searchable={true}
                             blackList={true}
                             className="menu-languages"
                           />
                         </div>
                       </div>
                     </Box>
                   </div>
                 </Box>
               </div>
             </div>
            )}
            {step === 2 && (
              <div className="nextStep" style={{}}>
                {/* Add your next step content here */}
                <div
                  style={{
                    background: "#9d91f6",
                    height: "60px",
                    // alignContent: "center",
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <h3 style={{ marginLeft: "10px" }}>Template</h3>
                  <Button
                    variant="outlined"
                    style={{
                      marginTop: "10px",
                      marginLeft: "700px",
                      border: "none",
                      color: "black",
                    }}
                    onClick={() => setStep(1)}
                  >
                    Go Back
                  </Button>
                </div>
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4> Create Custom templates </h4>
                  <div style={{}} onClick={CreateTemplate}>
                    <Button variant="contained">Submit</Button>
                  </div>
                </div>
                <hr style={{ width: "100%", marginRight: "" }} />
                <div className="" style={{ display: "flex" }}>
                  <div
                    className=""
                    style={{ marginLeft: "23px", marginTop: "10px" }}
                  >
                    <span style={{ fontWeight: "bold" }}>Header</span>
                    <br />
                    <span
                      style={{
                        fontSize: "13px",
                        color: "grey",
                        marginTop: "5px",
                      }}
                    >
                      Add a title or choose which type of media you want to use.
                    </span>
                    <div style={{ display: "flex" }}>
                      <Select
                        value={select}
                        onChange={handleSelect}
                        defaultValue="fixed"
                        sx={{
                          mt: "20px",
                          width: "30%",
                          height: "35px",
                          boxShadow: "0px 0px 5px 0px",
                        }}
                      >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Text">Text</MenuItem>
                        <MenuItem value="Media">Media</MenuItem>
                      </Select>
                      {select === "Text" && (
                        <div style={{}}>
                          <TextField
                            placeholder="Enter text"
                            sx={{
                              width: "500px",
                              height: "34px",
                              marginTop: "20px",
                              marginLeft: "20px",
                              boxShadow: "2px 2px 8px 0px",
                              borderRadius: "5px",
                            }}
                            name="header"
                            value={inputs.header}
                            onChange={(e) => handleInputs(e, null)}
                            InputProps={{ style: { height: "35px" } }}
                          />
                          <br />
                          {!showHeaderVariableInput && (
                            <Button
                              style={{
                                marginTop: "30px",
                                marginLeft: "380px",
                                color: "black",
                              }}
                              onClick={() => handleAddVariables("header")}
                            >
                              + Add Variable
                            </Button>
                          )}
                          {showHeaderVariableInput && (
                            <>
                              <div
                                style={{
                                  marginTop: "50px",
                                  marginLeft: "20px",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                Add Sample Text
                              </div>
                              <TextField
                                value={inputs.headerVariableVal}
                                name="headerVariable"
                                onChange={(e) => handleInputs(e, null)}
                                placeholder="Enter content for {{1}}"
                                sx={{
                                  width: "500px",
                                  height: "34px",
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                  boxShadow: "2px 2px 8px 0px",
                                  borderRadius: "5px",
                                }}
                                InputProps={{ style: { height: "35px" } }}
                              />
                            </>
                          )}
                        </div>
                      )}
                      {select === "Media" && (
                        <Box
                          sx={{
                            mt: "20px",
                            width: "60%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            marginLeft: "15px",
                          }}
                        >
                          <Button variant="contained" component="label">
                            Upload File
                            <input type="file" hidden />
                          </Button>
                          <span
                            style={{
                              fontSize: "13px",
                              color: "grey",
                              marginTop: "5px",
                            }}
                          >
                            Select an image or video file to upload.
                          </span>
                        </Box>
                      )}
                    </div>
                    {/* <hr style={{ width: "935px", marginRight: "50px" }}/> */}
                  </div>
                  <div>
                    <Box
                      sx={{
                        height: "200px",
                        width: "300px",
                        background: "lightgrey",
                        marginLeft: "30px",
                        marginTop: "35px",
                        borderRadius: ["5px", "10px", "10px", "10px"],
                      }}
                    >
                      {inputs.header}
                      {inputs.headerVariableVal}
                      {inputs.body}
                      {inputs.bodyVariableVal}
                    </Box>
                  </div>
                </div>
                <div
                  className="templateBody"
                  style={{
                    margin: "auto",
                    marginTop: "60px",
                    marginLeft: "20px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Body</span>
                  <br />
                  <span
                    style={{
                      fontSize: "13px",
                      color: "grey",
                      marginTop: "5px",
                      marginBottom: "15px",
                    }}
                  >
                    Enter the text for your template
                  </span>
                  <div>
                    <textarea
                      cols={80}
                      rows={10}
                      style={{
                        marginBottom: "5px",
                        marginTop: "10px",
                        boxShadow: "2px 3px 2px 0px",
                      }}
                      placeholder="Enter text here"
                      name="body"
                      value={inputs.body}
                      onChange={(e) => handleInputs(e, null)}
                    ></textarea>
                  </div>
                  {!showBodyVariableInput && (
                    <Button
                      style={{
                        marginLeft: "480px",
                        color: "black",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleAddBodyVariables()}
                    >
                      + ADD VARIABLES
                    </Button>
                  )}
                  
                  {inputs?.bodyVariableVal?.map(
                    (item, index) => (
                      console.log(item),
                      (
                        <div style={{ marginBottom: "20px" }} key={index}>
                          <div
                            style={{
                              marginTop: index === 0 ? "50px" : "20px",
                              fontWeight: "bold",
                              fontSize: "13px",
                            }}
                          >
                            Add Sample Text for Variable {index + 1}
                          </div>
                          <TextField
                            type="text"
                            value={item}
                            name="bodyVariable"
                            onChange={(e) => handleInputs(e, index)}
                            placeholder={`Enter content for {{${index + 1}}}`}
                            sx={{
                              width: "600px",
                              height: "34spx",
                              marginTop: "10px",
                              marginBottom: "20px",
                              boxShadow: "2px 2px 8px 0px",
                              borderRadius: "5px",
                            }}
                            InputProps={{ style: { height: "35px" } }}
                          />
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateTemplate;
