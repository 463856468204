import React, { useEffect } from "react";
import SideBar from "../SideBar/sidebar";
import { Box, TextField, Toolbar } from "@mui/material";
import "../profile-settings/facebookLogin.css";

function loadFbSdk() {
  return new Promise((resolve) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1499372314183020",
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
      resolve();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}

const FacebookButton = () => {
  useEffect(() => {
    loadFbSdk();
  }, []);

  const handleLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          console.log("Access Token:", accessToken); // Check granted permissions
          fetchBusinessAccounts(accessToken);
          // fetchPhoneDetails(accessToken);
          // window.FB.api(
          //   "/me/permissions",
          //   { access_token: accessToken },
          //   (permissionsResponse) => {
          //     console.log("Permissions response(52):", permissionsResponse); // Now call the /me/accounts endpoint

          //     window.FB.api(
          //       "/me/accounts",
          //       { access_token: accessToken },
          //       (accountsResponse) => {
          //         console.log("FB.api /me/accounts response", accountsResponse);
          //         if (accountsResponse && !accountsResponse.error) {
          //           if (
          //             accountsResponse.data &&
          //             accountsResponse.data.length > 0
          //           ) {
          //             const businessID = accountsResponse.data[0].id;
          //             const businessName = accountsResponse.data[0].name;
          //             console.log("Business ID:", businessID);
          //             console.log("Business Name:", businessName);
          //           } else {
          //             console.error(
          //               "No business accounts found.",
          //               accountsResponse
          //             );
          //           }
          //         } else {
          //           console.error(
          //             "Error fetching accounts:",
          //             accountsResponse.error
          //           );
          //         }
          //       }
          //     );
          //   }
          // );
        } else {
          console.error("User did not authorize the app.");
        }
      },
      { scope: "business_management,ads_management" }
    );
  };

  const fetchBusinessAccounts = (accessToken) => {
    window.FB.api(
      "/me/businesses",
      { access_token: accessToken },
      (businessesResponse) => {
        console.log("FB.api /me/businesses response", businessesResponse);
        if (businessesResponse && !businessesResponse.error) {
          if (businessesResponse.data && businessesResponse.data.length > 0) {
            const businessID = businessesResponse.data[0].id;
            const businessName = businessesResponse.data[0].name;

            console.log("Business ID:", businessID);
            console.log("Business Name:", businessName);

            // Fetch detailed business information
            fetchBusinessDetails(accessToken, businessID);

            // Call fetchPhoneDetails with businessID
            fetchPhoneDetails(accessToken, businessID);
          } else {
            console.error("No business accounts found.");
          }
        } else {
          console.error(
            "Error fetching business accounts:",
            businessesResponse.error
          );
        }
      }
    );
  };

  const fetchBusinessDetails = (accessToken, businessID) => {
    if (!businessID) {
      console.error("Business ID is undefined.");
      return;
    }

    window.FB.api(
      `/${businessID}`,
      { access_token: accessToken, fields: "id,name" },
      (businessDetailsResponse) => {
        console.log(`FB.api /${businessID} response`, businessDetailsResponse);
        if (businessDetailsResponse && !businessDetailsResponse.error) {
          const detailedBusinessID = businessDetailsResponse.id;
          const businessName = businessDetailsResponse.name;

          console.log("Detailed Business ID:", detailedBusinessID);
          console.log("Detailed Business Name:", businessName);
        } else {
          console.error(
            "Error fetching business details:",
            businessDetailsResponse.error
          );
        }
      }
    );
  };

  const fetchPhoneDetails = (accessToken, businessID) => {
    if (!businessID) {
      console.error("Business ID is undefined.");
      return;
    }

    window.FB.api(
      `/me/${businessID}/phone_numbers`,
      { access_token: accessToken },
      (businessDetailsResponse) => {
        console.log(
          `FB.api /${businessID}/phone_numbers response`,
          businessDetailsResponse
        );
        if (businessDetailsResponse && !businessDetailsResponse.error) {
          const phoneNumbers = businessDetailsResponse.data;

          phoneNumbers.forEach((phoneDetail) => {
            const phoneID = phoneDetail.id;
            //const phoneNumber = phoneDetail.display_phone_number;

            console.log("Phone ID:", phoneID);
            // console.log("Phone Number:", phoneNumber);
          });
        } else {
          console.error(
            "Error fetching phone details:",
            businessDetailsResponse.error
          );
        }
      }
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <SideBar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "#d7d3d3",
          // p: 2,
          pt: 1,
          overflow: "hidden",
          height: "100vh",
          // marginTop: "10px",
        }}
      >
        <Toolbar />
        <Box sx={{
            background: "white",
            height: "86vh",
            mt: 1,
            ml: 1.2,
            mr: 1.2,
          }}>
          <div className="facebookLogin">
            <h1 style={{ visibility: "hidden" }}>Profile Settings</h1>
            <div style={{ marginLeft: "30px", marginBottom: "15px" }}>
              <label style={{ fontWeight: "bolder" }}>Business Name</label>
              <div>
                <TextField
                  style={{ width: "40%" }}
                  inputProps={{ style: { height: "10px" } }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "30px", marginBottom: "20px" }}>
              <label style={{ fontWeight: "bolder" }}>Mobile Number</label>
              <div>
                <TextField
                  style={{ width: "40%", height: "30%" }}
                  inputProps={{ style: { height: "10px" } }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "30px", marginBottom: "20px" }}>
              <label style={{ fontWeight: "bolder" }}>Phone Id</label>
              <div>
                <TextField
                  style={{ width: "40%", height: "30%" }}
                  inputProps={{ style: { height: "10px" } }}
                />
              </div>
            </div>
            <div className="facebookButton" style={{ marginLeft: "30px" }}>
              <button onClick={handleLogin}>Login with Facebook</button>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default FacebookButton;
