import React, { useEffect, useState } from "react";
import SideBar from "../SideBar/sidebar";
import { Box, Button, MenuItem, Select, Toolbar } from "@mui/material";
import "../TemplateManagement/template.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { APIs } from "../../Shared/Config/config";
import WaApproved from "../../Assets/Images/WaApproved.png";
import WaInprogress from "../../Assets/Images/WaInprogress.png";
import WaRejected from "../../Assets/Images/WaRejected.png";
import { toast } from "react-toastify";
import Pagination from "../../Shared/Pagination/pagination";

function Template() {
  const navigate = useNavigate();
  const getSession = sessionStorage.getItem("X-Session");
  // const [error, setError] = useState("");
  const [templates, setTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("APPROVED"); // Set default status to "APPROVED"

  useEffect(() => {
    getTemplates(selectedStatus, 0);
  }, [selectedStatus]); // Fetch templates whenever selectedStatus changes

  const handleCreateTemplate = () => {
    navigate("/create-template");
  };

  const handlePageClick = (data) => {
    setCurrentPage(data);
    console.log("selectedPage", data);
    getTemplates(selectedStatus, data);
  };

  // const per_page = 5;

  const getTemplates = async (status, e) => {
    setCurrentPage(e);

    let limit = 10;
    let offset = e * limit;

    // const statusQuery = selectedStatus === "Inprogress" ? "NEW" : selectedStatus;

    let payLoad = {
      enabled: true,
      // templateName: "sale03",
      status: status,
      limit: limit,
      offset: offset,
      // sortBy: "createdtime",
      // orderBy: "desc",
    };

    const apiUrl = APIs.getTemplate;

    await axios
      .post(apiUrl, payLoad, {
        headers: {
          "X-session": getSession,
        },
      })

      .then((res) => {
        let response = res.data;
        if (response && response.data) {
          setTemplates(response.data.details);
          setPageCount(Math.ceil(response.data.count / limit));
          console.log("Response", response);
          // const successMessage = response.data.message || "There is no data";
          // toast.success(successMessage);
        }
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log(err);
          // setError("OTP has been Resent to", mobile);
          toast.error(`${err.response.data.error.message}`);
        }
      });
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(0); // Reset to first page when status changes
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideBar />
        <Box
          className="mainContent"
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#d7d3d3",
            // p: 2,
            pt: 1,
            overflow: "hidden",
            height: "100vh",
            // marginTop: "10px",
          }}
        >
          <Toolbar />
          <Box
            sx={{
              background: "white",
              height: "86vh",
              mt: 1,
              ml: 1.2,
              mr: 1.2,
            }}
          >
            <div className="template">
              <Select
                value={selectedStatus}
                onChange={handleStatusChange}
                sx={{ ml: "20px", mt: "20px", width: "21%" }}
              >
                <MenuItem value="APPROVED">
                  <img
                    src={WaApproved}
                    alt=""
                    style={{ height: "20px", marginRight: "5px" }}
                  />
                  Approved
                </MenuItem>
                <MenuItem value="Inprogress">
                  <img
                    src={WaInprogress}
                    alt=""
                    style={{ height: "20px", marginRight: "5px" }}
                  />
                  Inprogress
                </MenuItem>
                <MenuItem value="REJECTED">
                  <img
                    src={WaRejected}
                    alt=""
                    style={{ height: "20px", marginRight: "5px" }}
                  />
                  Rejected
                </MenuItem>
              </Select>
              <Button
                onClick={handleCreateTemplate}
                variant="contained"
                sx={{
                  ml: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: "-35px",
                  mr: "20px",
                  border: "none",
                }}
              >
                Create Template
              </Button>

              <div className="getTemplateContent">
                {templates?.map((template) => (
                  <div key={template.id} className="templateBlock">
                    <div className="leftContent">
                      {template.waStatus === "APPROVED" && (
                        <img src={WaApproved} alt="Approved" />
                      )}
                      {template.waStatus === "In progress" && (
                        <img src={WaInprogress} alt="Inprogress" />
                      )}
                      {template.waStatus === "REJECTED" && (
                        <img src={WaRejected} alt="REJECTED" />
                      )}
                    </div>
                    <div className="rightContent">
                      <div className="line">{template.templatename}</div>
                      <div className="line1">
                        {/* {template.templatedescription ? template.templatedescription : "No description available"} */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <Pagination
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Template;
