// import FacebookButton from './Layout/Components/profile-settings/facebookLogin';

import Login from "./Layout/Components/Login/login";
// import VerifyLoginOtp from "./Layout/Components/Login/verifyLoginOtp";
import Register from "./Layout/Components/Register/register";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import VerifyRegisterOtp from "./Layout/Components/Register/verifyRegisterOtp";
import Header from "./Layout/Components/Header/header";
import Dashboard from "./Layout/Components/Dashboard/dashboard";
import Template from "./Layout/Components/TemplateManagement/Template";
import BulkMessage from "./Layout/Components/BulkMessages/bulkMessage";
import FacebookButton from "./Layout/Components/profile-settings/facebookLogin";
import "./Layout/Components/TemplateManagement/template.css";
// import CreateTemplate from "./Layout/Components/TemplateManagement/createTemplate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateTemplate from "./Layout/Components/TemplateManagement/createTemplate";
import Campaign from "./Layout/Components/Campaign/campaign";
import SalesManagement from "./Layout/Components/SalesManagement/salesManagement";
import LandingPage from "./Layout/Components/LandingPage/landingPage";
import SalesPersonLogin from "./Layout/Components/Sales Representative login/salesPersonLogin";
import AdminChattingDetails from "./Layout/Components/AdminChattingDetails/adminChattingDetails";
import SalesPersonChatting from "./Layout/Components/SalesPesonChattingDetails/salesPersonChatting";

function App() {
  return (
    <>

      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LandingPage/>} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          {/* <Route exact path="/verifyOtp" element={<VerifyLoginOtp />} /> */}
          {/* <Route exact path="/verifyRegisterOtp" element={<VerifyRegisterOtp />} /> */}
          <Route exact path="/salesPersonLogin" element={<SalesPersonLogin />} />
          <Route exact path="/header" element={<Header />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/template-management" element={<Template />} />
          <Route exact path="/bulk-messages" element={<BulkMessage />} />
          <Route exact path="/profile-settings" element={<FacebookButton />} />
          <Route exact path="/create-template" element={<CreateTemplate />} />
          <Route exact path="/Sales_Campaign" element={<Campaign />} />
          <Route exact path="/Sales-Mgmt" element={<SalesManagement />} />
          <Route exact path="/ChattingDetails" element={<AdminChattingDetails/>} />
          <Route exact path="/SalesPersonChatingDetails" element={<SalesPersonChatting/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
