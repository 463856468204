import React, { useEffect, useState } from "react";
import Sidebar from "../SideBar/sidebar";
import { Box, Button, Dialog, Toolbar } from "@mui/material";
import "../SalesManagement/salesManagement.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { APIs } from "../../Shared/Config/config";
import axios from "axios";
import { toast } from "react-toastify";
import AddUser from "./addUser";
import Pagination from "../../Shared/Pagination/pagination";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

function SalesManagement() {
  const getSession = sessionStorage.getItem("X-Session");
  let [representative, setrepresentative] = useState([]);
  let [isOpen, setIsOpen] = useState(false);
  let [currentPage, setCurrentPage] = useState(0);
  let [pageCount, setPageCount] = useState(0);
  let [selectedRep, setSelectedRep] = useState(null);
  let [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  let [repToDelete, setRepToDelete] = useState(null);

  useEffect(() => {
    handleGetRepresentatives(0);
  }, []);

  const handleOpenDialog = () => {
    setSelectedRep(null);
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedRep(null);
    setIsOpen(false);
  };

  const handleEditClick = (rep) => {
    setSelectedRep(rep);
    setIsOpen(true);
  };

  const handleDeleteClick = (rep) => {
    setRepToDelete(rep);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setRepToDelete(null);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data);
    console.log("selectedPage", data);
    handleGetRepresentatives(data);
  };

  const handleGetRepresentatives = async (e) => {
    setCurrentPage(e);

    let limit = 10;
    let offset = e * limit;

    let payLoad = {
      limit: limit,
      offset: offset,
      sortBy: "createdDate",
      orderBy: "desc",
    };

    let apiurl = APIs.getRepresentative;

    await axios
      .post(apiurl, payLoad, {
        headers: {
          "X-session": getSession,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setrepresentative(result.executiveList);
        setPageCount(Math.ceil(result.count / limit));
        console.log("Reuslt", result);
        // toast.success(`${result.data.message}`);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log("Error", err);
          toast.error(`${err.data.error}`);
        }
      });
  };

  const handleDeleteRepresentative = async (id) => {
    let apiurl = `${APIs.deleteRepresentative}/${repToDelete.id}`;
    try {
      const res = await axios.delete(apiurl, {
        headers: {
          "X-Session": getSession,
        },
      });
      const message =
        res.data.data.message || "Representative added successfully";
      toast.success(message);
      handleDeleteClose(true);
      handleGetRepresentatives();
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status < 500
      ) {
        toast.error(`${err.response.data.error}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "#d7d3d3",
          // p: 2,
          pt: 1,
          overflow: "hidden",
          height: "100vh",
          // marginTop: "10px",
        }}
      >
        <Toolbar />

        <Box
          sx={{
            background: "white",
            height: "86vh",
            mt: 1,
            ml: 1.2,
            mr: 1.2,
            overflow: "auto",
          }}
        >
          <div className="salesMgmt">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "sticky",
              }}
            >
              <div
                style={{
                  marginTop: "-10px",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <h3>Sales Representative</h3>
              </div>
              <div>
                <Button
                  variant="outlined"
                  style={{
                    // marginLeft: "490px",
                    margin: "10px",
                    color: "#FF6816",
                  }}
                  onClick={handleOpenDialog}
                >
                  + Add Sales Representative
                </Button>
              </div>
            </div>
            {isOpen && (
              <AddUser
                isOpen={isOpen}
                onClose={handleCloseDialog}
                handleGetRepresentatives={handleGetRepresentatives}
                selectedRep={selectedRep}
              />
            )}
            <hr style={{}} />
            <div className="tableData">
              <TableContainer
                className="hidden-scrollbar"
                component={Paper}
                sx={{
                  // minHeight: 225,
                  // maxHeight: 390,
                  overflow: "auto",
                  borderRadius: "10px",
                  width: "95%",
                  marginLeft: "23px",
                  marginTop: "20px",
                  marginBottom: "8px",
                }}
              >
                <Table
                  sx={{ backgroundColor: "#f0f0f0" }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead
                    className="tableHead"
                    style={{ fontWeight: "bold" }}
                    sx={{ position: "sticky" }}
                  >
                    <TableRow className="tableCell">
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        Sl.No
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        Sales Representative
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "blue",
                          color: "white",
                          marginRight: "-20px",
                        }}
                      >
                        Mobile
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        style={{
                          align: "left",
                          fontWeight: "bold",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        Edit
                      </TableCell>
                      <TableCell
                        style={{
                          align: "left",
                          fontWeight: "bold",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {representative?.map((user, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                            overflow: "auto",
                          },
                        }}
                      >
                        {/* <TableCell component="th" scope="row">
                          {user.id}
                        </TableCell> */}
                        <TableCell>{currentPage * 10 + index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          {user.name}
                        </TableCell>
                        <TableCell align="left">{user.mobileNumber}</TableCell>
                        <TableCell align="left">{user.email}</TableCell>
                        <TableCell align="left">
                          <DriveFileRenameOutlineIcon
                            onClick={() => handleEditClick(user)}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteClick(user)}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                        {/* <TableCell
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      background: "green",
                                      borderRadius: "10px",
                                      boxShadow: "0px 5px 10px 0px",
                                    },
                                  }}
                                  align="center"
                                >
                                  <div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      background: "red",
                                      borderRadius: "10px", 
                                      boxShadow: "0px 5px 10px 0px",
                                    },
                                  }}
                                  align="center"
                                >
                                  <div>
                                  </div>
                                </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className="pagination"></div>
              </TableContainer>
            </div>
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                currentPage={currentPage}
              />
            </div>
          </div>
        </Box>
      </Box>
      <Dialog
        disableEnforceFocus
        open={confirmDeleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this representative?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteRepresentative}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SalesManagement;
