import { Box, TextField, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../AdminChattingDetails/adminChattingDetails.css";
import Sidebar from "../SideBar/sidebar";
import { APIs } from "../../Shared/Config/config";
import axios from "axios";
import { toast } from "react-toastify";
import LinearProgress from "@mui/material/LinearProgress";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const AdminChattingDetails = () => {
  const getSession = sessionStorage.getItem("X-Session");
  const [chatData, setChatData] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);

  useEffect(() => {
    const getChatData = async () => {
      await fetchChatData();
    };
    getChatData();
  }, []);

  const cleanCustomerMessage = (message = "") => {
    if (message === null || message === undefined) {
      return ""; // Or handle it as you see fit
    }
    return message.replace("{body=", "").replace("}", "");
  };

  const fetchChatData = async () => {
    const apiurl = APIs.getChatData;

    await axios
      .post(
        apiurl,
        {},
        {
          headers: {
            "X-session": getSession,
          },
        }
      )
      .then((res) => {
        const response = res.data;
        console.log("API Response:", response);

        // Check if the response has the expected structure
        if (response.data && response.data.chatData) {
          setChatData(response.data.chatData);
        } else {
          console.log("No chatData found in the response.");
          toast.error("No chat data found.");
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status >= 400 &&
          err.response.status <= 500
        ) {
          toast.error(`${err.response.data.error.message}`);
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  const cleanMessage = (message) => {
    if (!message) {
      return ""; // Return an empty string or a default message if the message is null or undefined
    }
    try {
      const parsedMessage = JSON.parse(message);
      return parsedMessage.body || ""; // Extract and return the `body` content
    } catch (error) {
      console.error("Failed to parse message:", error);
      return message; // If parsing fails, return the original message
    }
  };

  const truncateMessage = (message, wordLimit) => {
    const words = message.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return message;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "#d7d3d3",
          pt: 1,
          overflow: "hidden",
          height: "100vh",
        }}
      >
        <Toolbar />

        <Box
          sx={{
            background: "white",
            height: "86vh",
            mt: 1,
            ml: 1.2,
            mr: 1.2,
            overflow: "auto",
          }}
        >
          <div className="main-container">
            <div className="chat-container">
              <div className="searchUser">
                <TextField
                  placeholder="Search by name"
                  InputProps={{
                    className: "search-Input", // Apply the CSS class
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {chatData.length > 0 ? (
                chatData.map((chat, index) => (
                  <div
                    key={index}
                    className="chat-content"
                    onClick={() => handleChatClick(chat)} // Move the onClick inside map and use an arrow function
                  >
                    <p>
                      <strong>{chat.customerName}</strong>
                    </p>
                    {chat.chatData && chat.chatData.length > 0 ? (
                      <div>
                        <p style={{ fontSize: "11px" }}>
                          {truncateMessage(
                            cleanCustomerMessage(
                              chat.chatData[chat.chatData.length - 1]
                                .customerMessage
                            ),
                            5
                          )}
                        </p>
                      </div>
                    ) : (
                      <p>No messages available</p>
                    )}
                  </div>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>
                  Loading <LinearProgress />{" "}
                </p>
              )}
            </div>
            <div className="chat-details">
              {selectedChat ? (
                selectedChat.chatData && selectedChat.chatData.length > 0 ? (
                  selectedChat.chatData.map((message, idx) => (
                    <div key={idx} className="message-container">
                      {idx === 0 && ( // Only show the customer name once, before the first message
                        <div className="chat-Header">
                          <span>
                            <AccountCircleIcon style={{marginLeft:'10px'}} />
                          </span>
                          <span className="customer-name">
                            {selectedChat.customerName}
                          </span>
                        </div>
                      )}
                      <div className="message">
                        <p>{cleanCustomerMessage(message.customerMessage)}</p>
                      </div>
                      {message.salesPersonMessage && (
                        <div className="salesperson-message">
                          <p>{cleanMessage(message.salesPersonMessage)}</p>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No messages available</p>
                )
              ) : (
                <p>Select a user to view messages</p>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminChattingDetails;
