import React from 'react';
import ReactPaginate from 'react-paginate';
import '../Pagination/pagination.css';

const Pagination = ({ pageCount, handlePageClick, currentPage }) => {
  const onPageClick = ({ selected: selectedPage }) => {
    handlePageClick(selectedPage);
  };

  // Check if pageCount is greater than 1 to determine if pagination is needed
  const shouldRenderPagination = pageCount > 1;

  return shouldRenderPagination ? (
    <ReactPaginate
      pageCount={pageCount}
      onPageChange={onPageClick}
      previousLabel={'Previous'}
      nextLabel={'Next'}
      containerClassName={'pagination'}
      activeClassName={'active'}
      previousClassName={currentPage === 0 ? 'disabled' : ''}
      nextClassName={currentPage === pageCount - 1 ? 'disabled' : ''}
      disabledClassName={'disabled'}
    />
  ) : null; // Render nothing if there is only one page or no data
};

export default Pagination;