// import { AppBar,  MenuItem, Select, Toolbar, Typography } from "@mui/material";
// // import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
// // import AddUser from "../SalesManagement/addUser";

// function Header() {

//   const drawerWidth = 300;
//   // const getPath = window.location.pathname;
//   const location = useLocation();

//   return (
//     <AppBar
//       position="fixed"
//       sx={{
//         width: `calc(100% - ${drawerWidth}px)`,
//         ml: `${drawerWidth}px`,
//         padding: "12px",
//         background: "white",
//         color: "black",
//         height:'75px',
//       }}
//     >
//       <Toolbar>
//         {(() => {
//           switch (location.pathname) {
//             case "/template-management":
//               return (
//                 <>
//                   <Typography variant="h6" noWrap component="div">
//                     Template management
//                   </Typography>
//                   <Select
//                     value="WhatsApp"
//                     sx={{ ml: "auto", width: "20%", height: "5vh" }}
//                   >
//                     <MenuItem value="WhatsApp">WhatsApp</MenuItem>
//                   </Select>
//                 </>
//               );
//             case "/bulk-messages":
//               return (
//                 <Typography variant="h6" noWrap component="div">
//                   Bulk message
//                 </Typography>
//               );
//             case "/profile-settings":
//               return (
//                 <Typography variant="h6" noWrap component="div">
//                   Profile settings
//                 </Typography>
//               );
//             case "/Sales_Campaign":
//               return (
//                 <Typography variant="h6" noWrap component="div">
//                  Sales  Campaign
//                 </Typography>
//               );
//             case "/Sales-Mgmt":
//               return (
//                 <>
//                   <Typography variant="h6" noWrap component="div">
//                     Sales Management
//                   </Typography>
//                 </>
//               );
//             default:
//               return (
//                 <Typography variant="h6" noWrap component="div">
//                   Dashboard header
//                 </Typography>
//               );
//           }
//         }).call(this)}
//       </Toolbar>
//     </AppBar>
//   );
// }

// export default Header;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { IoMdLogOut } from "react-icons/io";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import '../Header/header.css'

function Header() {
  const location = useLocation();

  const navigate = useNavigate();
  const [confirmLogOut, setConfirmLogOut] = useState(false);

  // Map routes to sidebar names
  const routeNameMapping = {
    "/dashboard": "Dashboard",
    "/template-management": "Template Management",
    "/bulk-messages": "Bulk Messages",
    "/Sales_Campaign": "Campaign",
    "/Sales-Mgmt": "Sales Management",
    "/profile-settings": "Profile Settings",
    "/ChattingDetails": "Team Inbox",
  };

  // Get the sidebar name based on the current pathname
  const headerTitle = routeNameMapping[location.pathname] || "Page Not Found";

  const handleLogout = () => {
    setConfirmLogOut(false);
    navigate('/');
  };

  const handleLogOutOpen = () => {
    setConfirmLogOut(true);
  }

  const handleLogoutClose = () => {
    setConfirmLogOut(false);
  }

  return (
    <div
      className="header"
    >
      <div className="headerTitle">
        <h2>{headerTitle}</h2>
      </div>
      <div  className="logOut"  onClick={handleLogOutOpen}>
        Logout <IoMdLogOut style={{ marginLeft: "8px" }}  />
      </div>

      <Dialog
        className="dialogModal"
        disableEnforceFocus
        open={confirmLogOut}
        onClose={handleLogoutClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Header;
