import React from "react";
import "../LandingPage/landingPage.css";
import EchoCastLogin from "../../Assets/Images/Echocast_logo.png";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const LandingPage = () => {
  const navigate = useNavigate();

  const handleEchoCastLogin = () => {
    navigate("/login");
  };

  const handleSalesPersonLogin = () => {
    navigate('/salesPersonLogin')
  }

  return (
    <div className="container">
      <div className="card-container">
        <div className="loginImage1">
          <img src={EchoCastLogin} alt="EchoCastLoginPage" />
          <div className="body-content">
            <p style={{fontSize:'15px', color:'black', fontWeight:'bolder'}}>Admin Login</p>
            <p style={{ fontWeight: "bold", fontSize: "15px", color: "white" }}>
              Revolutionizing the Way of sending WhatsApp Bulk Messages.
            </p>
            <p style={{ color: "rgb(165 204 242)" }}>
              Welcome to EchoCast! Our platform offers a powerful solution for
              managing and sending bulk messages via WhatsApp.
            </p>
            <ul>
              <li>
                <strong>Bulk Messaging:</strong> Send personalized messages to
                large groups effortlessly.
              </li>
              <li>
                <strong>Template Management:</strong> Create and manage message
                templates for quick and consistent communication.
              </li>
              {/* <li>
                <strong>Automated Replies:</strong> Set up automated responses
                for common inquiries.
              </li> */}
            </ul>
            <div className="signInBtn">
              <Button variant="contained" sx={{}} onClick={handleEchoCastLogin}>
                SignIn{" "}
                <small style={{ margin: "3px", textDecoration: "underline" }}>
                  Or
                </small>{" "}
                SignUp
              </Button>
            </div>
          </div>
        </div>
        <div className="loginImage2">
          <img src={EchoCastLogin} alt="EchoCastLoginPage" />
          <div className="body-content">
            <p style={{fontSize:'15px', color:'black', fontWeight:'bolder'}}> Sales representative login </p>
            <p style={{ fontWeight: "bold", fontSize: "15px", color: "white" }}>
              Revolutionizing the Way of sending WhatsApp Bulk Messages.
            </p>
            <p style={{ color: "rgb(165 204 242)" }}>
              Welcome to EchoCast! Our platform allows you to manage and send
              bulk messages via WhatsApp, with a seamless handoff to sales
              representatives for handling user replies.
            </p>
            <ul>
              {/* <li>
                <strong>Template Management:</strong> Create and manage WhatsApp
                message templates for quick, consistent communication.
              </li> */}
              <li>
                <strong>Sales-Driven Responses:</strong> When users reply to
                WhatsApp messages, your sales team can step in to engage and
                continue the conversation.
              </li>
              <li>
                <strong>Automated Replies:</strong> Set up automated responses
                for common inquiries, with sales representatives ready to take
                over when needed.
              </li>
            </ul>
            <div className="signInBtn">
              <Button variant="contained" sx={{}} onClick={handleSalesPersonLogin}>
                SignIn
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
