import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Grid, TextField, Button, Typography, InputAdornment } from "@mui/material";
import axios from "axios";
import { APIs } from "../../Shared/Config/config";
import Echocast_logo from "../../Assets/Images/Echocast_logo.png";
import Echocast_left_image from "../../Assets/Images/Echocast_Left_image.jpg";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../Login/login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const location = useLocation();
  const mobileNumber = location.state?.mobile;

  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [business, setBusiness] = useState("");
  const [otp, setOtp] = useState("");
  const [mobErrMsg, setMobErrMsg] = useState("");
  const [otpErrMsg, setOtpErrMsg] = useState("");
  const [businessErrMsg, setBusinessErrMsg] = useState("");
  const [error, setError] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading
  let [countryCode, setCountryCode] = useState("in");
  let [dialCode, setDialCode] = useState();

  let handleOnChange = (phone, mobileData) => {
    let cd = mobileData.dialCode.length;
    let number = phone.slice(cd);
    setMobile(number);
    setDialCode(mobileData.dialCode);
    setCountryCode(mobileData.countryCode);
  };

  const handleGetOtp = async (event) => {
    event.preventDefault();
    setMobErrMsg("");
    setLoading(true); // Set loading to true when the request starts

    if (!business) {
      setBusinessErrMsg("Business Name is required");
      setLoading(false); // Reset loading state
      return;
    }

    if (!mobile) {
      setMobErrMsg("Mobile number is required");
      setLoading(false); // Reset loading state
      return;
    }

    const requestData = {
      countryCode: `+${dialCode}`,
      mobileNumber: mobile,
      businessName: business,
      userType: 1,
    };

    await axios
      .post(APIs.getOtp, requestData)
      .then((res) => {
        let result = res.data.data;
        console.log(result);
        sessionStorage.setItem("X-Session", result.session);
        setOtpSent(true);
        navigate("", { state: { mobileNumber: mobile } });
        toast.success("OTP has been sent successfully!");
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log(err);
          setError("", mobile);
          toast.error(`${err.response.data.error.message}`);
        }
      })
      .finally(() => {
        setLoading(false); // Reset loading state when the request completes
      });
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setOtpErrMsg("");
    setLoading(true); // Set loading to true when verifying OTP

    if (!otp) {
      setOtpErrMsg("OTP is required");
      setLoading(false); // Reset loading state
      return;
    }

    const requestData = {
      countryCode: `+${dialCode}`,
      mobileNumber: mobile,
      businessName: business,
      otp: otp,
      userType: 1,
    };

    await axios
      .post(APIs.verifyOtp, requestData)
      .then((res) => {
        let result = res.data.data;
        console.log(result);
        sessionStorage.setItem("X-Session", result.session);
        navigate("/dashboard");
        toast.success("LoggedIn successfully!");
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          setError("OTP has been Resent to", mobile);
          toast.error(`Error: ${err.response.data.message}`);
        }
      })
      .finally(() => {
        setLoading(false); // Reset loading state when the request completes
      });
  };

  return (
    <div className="container">
      <Container
        maxWidth="xl"
        style={{
          backgroundColor: "grey",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <img
              src={Echocast_left_image}
              alt="Login"
              style={{ width: "100%", height: "100vh" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              backgroundColor: "white",
              padding: "70px",
              borderRadius: "5px",
            }}
          >
            <Grid item xs={12} m={6}>
              <img
                src={Echocast_logo}
                alt="Logo"
                style={{ width: "50%", display: "block", margin: "auto" }}
              />
            </Grid>
            <div style={{ textAlign: "center", marginTop: "-40px" }}>
              <Typography variant="h6" gutterBottom>
                Sign up to Echocast
              </Typography>
              <Typography variant="body2" style={{ color: "grey" }}>
                Sign up to create your Echocast account
              </Typography>
            </div>

            <form style={{ marginTop: "50px" }}>
              <div className="phone">
                <label style={{ marginLeft: "90px" }}>
                  Enter Business Name
                </label>

                <TextField
                  type="text"
                  placeholder="Enter Business Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  style={{
                    width: "65%",
                    display: "block",
                    margin: "auto",
                    borderRadius: "5px",
                    background: otpSent ? "lightgrey" : "none",
                  }}
                  disabled={otpSent}
                  value={business}
                  onChange={(e) => setBusiness(e.target.value)}
                />
                <div
                  style={{
                    fontSize: "0.8em",
                    color: "red",
                    width: "65%",
                    display: "block",
                    margin: "auto",
                    marginBottom: "18px",
                  }}
                >
                  {businessErrMsg}
                </div>
              </div>

              <div className="mobile">
                <div className="phone">
                  <label>Enter Mobile Number</label>
                  {otpSent && (
                    <Button
                      style={{ fontSize: "10px", marginLeft: "10px" }}
                      onClick={() => setOtpSent(false)}
                    >
                      Edit Number
                    </Button>
                  )}
                  <PhoneInput
                    type="text"
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    style={{
                      width: "65%",
                      display: "block",
                      margin: "auto",
                      borderRadius: "5px",
                      background: otpSent ? "lightgrey" : "none",
                    }}
                    disabled={otpSent}
                    inputProps={{ maxLength: 15, pattern: "[0-9]*" }}
                    country={countryCode}
                    onChange={(value, mobileData) =>
                      handleOnChange(value, mobileData)
                    }
                    value={mobileNumber}
                    countryCodeEditable={true}
                  />
                </div>
                <div
                  style={{
                    fontSize: "0.8em",
                    color: "red",
                    width: "65%",
                    display: "block",
                    margin: "auto",
                    marginBottom: "18px",
                  }}
                >
                  {mobErrMsg}
                </div>
              </div>

              {otpSent && (
                <div className="Otp">
                  <label style={{ marginLeft: "90px" }}> Enter OTP</label>
                  <TextField
                    type="text"
                    placeholder="Enter the OTP"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    inputProps={{ maxLength: 6, pattern: "[0-9]*" }}
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            variant="body2"
                            sx={{
                              cursor: "pointer",
                              color: "blue",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                            onClick={handleGetOtp}
                          >
                            Resend OTP
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "65%",
                      display: "block",
                      margin: "auto",
                      borderRadius: "5px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "0.8em",
                      color: "red",
                      width: "65%",
                      display: "block",
                      margin: "auto",
                      marginBottom: "18px",
                    }}
                  >
                    {otpErrMsg}
                  </div>
                </div>
              )}

              <Button
                onClick={otpSent ? handleVerifyOtp : handleGetOtp}
                variant="contained"
                style={{
                  width: "65%",
                  display: "block",
                  margin: "auto",
                  borderRadius: "5px",
                  backgroundColor: "#5c77f8",
                  color: "white",
                  textTransform: "none",
                }}
                disabled={loading} // Disable the button while loading
              >
                {loading
                  ? otpSent
                    ? "Verifying..."
                    : "Loading..."
                  : otpSent
                  ? "Verify OTP"
                  : "Get OTP"}{" "}
                {/* Show loading state */}
              </Button>

              <div style={{ textAlign: "center", marginTop: "16px" }}>
                <Link
                  to="/login"
                  style={{ color: "#5c77f8", textDecoration: "none" }}
                >
                  Already have an account? Sign in
                </Link>
              </div>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Register;
