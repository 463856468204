// import React from 'react'
// // import { Drawer as MUIdrawer, ListItem, ListItemIcon, ListItemText, ListItemButton, List, Drawer } from '@mui/material'
// import { Link, useLocation } from 'react-router-dom';

// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// // import InboxIcon from '@mui/icons-material/MoveToInbox';
// // import MailIcon from '@mui/icons-material/Mail';
// import Header from '../Header/header';
// import Echocast_logo from '../../Assets/Images/Echocast_logo.png';
// import SidebarSquare from '../../Assets/Images/SidebarSquare.png';

// function Sidebar() {

//   const routes = ['/dashboard', '/template-management', '/bulk-messages',  '/Sales_Campaign', '/Sales-Mgmt', '/profile-settings'];
//   const sideBarNames = ['Dashboard', 'Template Management', 'Bulk Messages',  'Campaign', 'Sales Management', 'Profile Settings'];
//   const drawerWidth = 300;

//   const location = useLocation();

//   return (

//     <Box sx={{ display: 'flex' }}>
//       {/* ------------------> HEADER <----------------- */}
//       <Header />

//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//             background : '#3a3b4b'
//           },
//         }}
//         variant="permanent"
//         anchor="left"
//       >
//         <div style={{ marginTop: '40px'}}>
//           <img src={Echocast_logo} alt='' style={{ height: '40px', maxWidth: '100%', display: 'flex', margin: 'auto' }} />
//         </div>
//         <Toolbar />
//         <List sx={{color: 'white'}}>
//           {sideBarNames.map((text, index) => (
//             <ListItem key={text} disablePadding>
//               <ListItemButton style={{backgroundColor: location.pathname === routes[index] ? 'rgb(255, 104, 22)' : 'inherit', cursor: 'pointer'}} component={Link} to={routes[index]}>
//                 <ListItemIcon>
//                   <img src={SidebarSquare} alt='SidebarImg' style={{height: '30px', width: '30px'}} />
//                 </ListItemIcon>
//                 <ListItemText primary={text} />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </List>
//       </Drawer>
//     </Box>
//   )
// }

// export default Sidebar

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import EchoCastLogo from "../../Assets/Images/Echocast_logo.png";
import HomeIcon from "@mui/icons-material/Home";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CompareIcon from '@mui/icons-material/Compare';
import AssistantIcon from '@mui/icons-material/Assistant';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Header from "../Header/header";
import TelegramIcon from '@mui/icons-material/Telegram';

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const routes = [
    "/dashboard",
    "/template-management",
    "/bulk-messages",
    "/Sales_Campaign",
    "/Sales-Mgmt",
    "/profile-settings",
    "/ChattingDetails"
  ];
  const sideBarNames = [
    "Dashboard",
    "Template Management",
    "Bulk Messages",
    "Campaign",
    "Sales Management",
    "Profile Settings",
    "Team Inbox"
  ];
  // const drawerWidth = 300;

  const location = useLocation();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const iconMapping = {
    "Dashboard": <HomeIcon />,
    "Template Management": <LocalMallIcon />,
    "Bulk Messages": <AttachEmailIcon />,
    "Sales Campaign": <CompareIcon />,
    "Sales Management": <AssistantIcon />,
    "Profile Settings": <SettingsSuggestIcon />,
    "Team Inbox": <TelegramIcon />
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
          <Header/>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={EchoCastLogo}
            alt="EchoCast"
            style={{ width: "15vw" }}
          />

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ color: "white" }}>
          {sideBarNames.map((text, index) => (
            <ListItem key={text} style={{ color: "black" }} disablePadding>
              <ListItemButton
                style={{
                  backgroundColor:
                    location.pathname === routes[index]
                      ? "rgb(255, 104, 22)"
                      : "inherit",
                  cursor: "pointer",
                }}
                component={Link}
                to={routes[index]}
              >
                <ListItemIcon>
                  {/* <img src={SidebarSquare} alt='SidebarImg' style={{height: '30px', width: '30px'}} />
                   */}
                  {iconMapping[text]}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
