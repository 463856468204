import React from 'react'
import SideBar from '../SideBar/sidebar';
import { Box, Toolbar } from '@mui/material';

function Dashboard() {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <SideBar />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: '#d7d3d3', p: 3 , overflow: 'hidden', height: '92vh'}}
        >
          <Toolbar />
          <div className='dahsboard'>
            <h1>Dashboard</h1>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default Dashboard;