

const BASE_URL = "https://restapi-qa.theshortlistd.com/"

export const APIs = {
    getOtp: `${BASE_URL}EchoCast/Login/gotp`,
    verifyOtp: `${BASE_URL}EchoCast/Login/votp`,
    getTemplate: `${BASE_URL}AlertsCascade/Whatsapp/GetAllTemplates?`,
    createTemplate: `${BASE_URL}AlertsCascade/Whatsapp/CreateTemplate`,
    uploadCsv: `${BASE_URL}AlertsCascade/Template/UploadCSV`,
    getUploadStatus: `${BASE_URL}AlertsCascade/Template/getUploadStatus`,
    getUploadStatusFile: `${BASE_URL}AlertsCascade/Template/download`,
    addRepresentative: `${BASE_URL}AlertsCascade/Sales/AddExecutive`,
    getRepresentative: `${BASE_URL}AlertsCascade/Sales/GetExecutives`,
    updateRepresentative:`${BASE_URL}AlertsCascade/Sales/UpdateExecutive`,
    deleteRepresentative: `${BASE_URL}AlertsCascade/Sales/DeleteExecutive`,
    createCampaign: `${BASE_URL}AlertsCascade/Campaign/Create`,
    getCampaignList: `${BASE_URL}AlertsCascade/Campaign/GetList`,
    updateCampaign: `${BASE_URL}AlertsCascade/Campaign/Update`,
    disableCampaign: `${BASE_URL}AlertsCascade/Campaign/StopStart`,
    salesCampaignMapping: `${BASE_URL}AlertsCascade/Campaign/AddSalesExecutives`,
    getChatData: `${BASE_URL}AlertsCascade/Chat/getChatData`,
    salesPersonChatting: `${BASE_URL}AlertsCascade/Chat/chat`
} 