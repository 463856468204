import React, { useEffect, useRef, useState } from "react";
import SideBar from "../SideBar/sidebar";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import SelectBulk from "../../Assets/Images/Select_Bulk.png";
import "../BulkMessages/bulkMessage.css";
import CloseIcon from "@mui/icons-material/Close";
// import { useNavigate } from "react-router-dom";
import { APIs } from "../../Shared/Config/config";
import axios from "axios";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "../../Shared/Pagination/pagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BulkMessage() {
  const fileInputRef = useRef(null);
  // const navigate = useNavigate();
  const getSession = sessionStorage.getItem("X-Session");
  let [selectFile, setselectFile] = useState(1);
  let [selectedFile, setSelectedFile] = useState(null);
  let [showError, setShowError] = useState(false);
  let [errorMsg, setErrorMsg] = useState("");
  let [fileName, setFileName] = useState("");
  let [showSuccess, setShowSuccess] = useState(false);
  let [approvedTemplates, setApprovedTemplates] = useState([]);
  let [selectedTemplate, setSelectedTemplate] = useState("");
  let [uploadStatus, setUploadStatus] = useState([]);
  let [currentPage, setCurrentPage] = useState(0);
  let [pageCount, setPageCount] = useState(0);
  let [selectedCampaign, setSelectedCampaign] = useState("");
  let [campaignList, setCampaignList] = useState([]);
  const [open, setOpen] = useState(false);
  const [fileParams, setFileParams] = useState({ val: null, id: null });
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterOption, setFilterOption] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filter, setFilter] = useState("all");
  // const [filterOptions, setFilterOptions] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // let [isDisable, setIsVisible] = useState(true)

  // useEffect(() => {
  //   getApprovedTemplates();
  // }, []);

  // const handleButtonDisable = () => {
  //   setIsVisible(false);
  //   // Perform any other actions you need on button click
  // };

  // const handleOpenAlert = (val, id) => {
  //   setFileParams({ val, id });
  //   setOpen(true);
  // };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterOption = (option) => {
    setFilterOption(option);
    setFilterAnchorEl(null); // Close the menu on option selection
  };

  const handleDateChange = (type, value) => {
    if (type === "start") {
      setStartDate(value);
    } else if (type === "end") {
      setEndDate(value);
    }
  };

  // const filteredData = campaignList.filter((list) => {
  //   const startDateFilter = new Date(startDate);
  //   const endDateFilter = new Date(endDate);
  //   const listStartDate = new Date(list.startDate);

  //   return (
  //     (!startDate || listStartDate >= startDateFilter) &&
  //     (!endDate || listStartDate <= endDateFilter)
  //   );
  // })

  const handleFilterChange = (status) => {
    setFilter(status);
  };

  const fetchFilteredStatus = () => {
    handleFilterChange(statusFilter);
  };

  const handleCloseAlert = (confirm) => {
    if (confirm) {
      handleGetStatusFile(fileParams.val, fileParams.id);
      console.log(setFileParams);
    }
    setOpen(false);
  };

  const handleSelectFile = () => {
    setselectFile(selectFile + 1);
  };

  const handleClose = () => {
    // Reset the form state
    setselectFile(1);
    setSelectedFile(null);
    setShowError(false);
    setErrorMsg("");
    setFileName("");
    setShowSuccess(false);
    // navigate(setselectFile(2));
  };

  // const goToUploadStatus = () => {
  //   setselectFile(3);
  // };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["text/csv"];
    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
      setFileName(file.name);
      setShowError(false);
      setErrorMsg("");
    } else {
      // Reset the file input value to prevent onChange from triggering without a new file
      fileInputRef.current.value = "";
      setShowError(true);
      setErrorMsg("Please select a valid CSV file.");
    }
  };

  const getApprovedTemplates = async () => {
    const payLoad = {
      enabled: true,
      status: "Approved",
      sortBy: "createdtime",
      orderBy: "desc",
    };

    const apiUrl = APIs.getTemplate;

    await axios
      .post(apiUrl, payLoad, {
        headers: {
          "X-session": getSession,
        },
      })

      .then((res) => {
        let response = res.data;
        if (response && response.data) {
          setApprovedTemplates(response.data.details);
          // setPageCount(Math.ceil(response.data.count / limit));
          console.log("Response", response);
          // const successMessage = response.data.message || "There is no data";
          // toast.success(successMessage);
        }
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log(err);
          toast.error(`${err.response.data.error.message}`);
        }
      });
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  const handleCampaignChange = (event) => {
    const selectedCampaignId = event.target.value;
    const campaign = campaignList.find((c) => c.id === selectedCampaignId);
    setSelectedCampaign(campaign);
  };

  const handleSend = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setShowError(true);
      setErrorMsg("Please select a file.");
      return;
    }

    if (!selectedTemplate) {
      setShowError(true);
      setErrorMsg("Please select a template.");
      return;
    }

    if (!selectedCampaign || !selectedCampaign.id) {
      setShowError(true);
      setErrorMsg("Please select a Campaign.");
      return;
    }

    const apiUrl = APIs.uploadCsv;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("templatename", selectedTemplate);
    formData.append("languageCode", "en");
    formData.append("campaignId", selectedCampaign.id);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          // "Content-Type": "multipart/form-data",
          "X-session": getSession,
        },
      });

      const result = response.data;
      toast.success(`${result.message}`);
      console.log("File uploaded successfully", result);
      setSelectedFile(null);
      setFileName("");
      setSelectedTemplate("");
      fileInputRef.current.value = "";
      setselectFile(3);

      getUploadStatus();
    } catch (err) {
      if (err.response) {
        console.error("Error response:", err.response);
        toast.error(
          `File upload failed: ${
            err.response.data.error.message || err.response.statusText
          }`
        );
      } else if (err.request) {
        console.error("Error request:", err.request);
        toast.error(
          "File upload failed: No response from server. Please try again."
        );
      } else {
        console.error("Error message:", err.message);
        toast.error(`File upload failed: ${err.message}`);
      }
    }
  };

  const getUploadStatus = async (e) => {
    // Ensure 'e' is a valid number, defaulting to 0 if it's invalid
    if (isNaN(e) || e === null || e === undefined) {
      e = 0;
    }

    setCurrentPage(e);

    let limit = 10;
    let offset = e * limit;

    let payLoad = {
      limit: limit,
      offset: offset,
      sortBy: "createdtime",
      orderBy: "desc",
    };

    let apiUrl = APIs.getUploadStatus;

    await axios
      .post(apiUrl, payLoad, {
        headers: {
          "X-session": getSession,
        },
      })
      .then((res) => {
        let response = res.data.data;
        setUploadStatus(response.details);
        setPageCount(Math.ceil(response.count / limit));
        console.log("Result", response);
        // toast.success(`${response.data.message}`);
      })
      .catch((err) => {
        console.log("This is error", err);

        if (err.response.status >= 400 && err.response.status < 500) {
          toast.error(`${err.response.data.error.message}`);
        }
      });
  };

  const handlePageClick = (data) => {
    setCurrentPage(data);
    console.log("selectedPage", data);
    getUploadStatus(data);
  };

  const handleGetStatusFile = async (val, id) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("flag", val);

    let apiUrl = `${APIs.getUploadStatusFile}?id=${id}&flag=${val}`;

    await axios({
      method: "get",
      url: apiUrl,
      // data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-session": getSession,
      },
      responseType: "blob",
    })
      .then((res) => {
        setOpen(false);

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${val}.csv`); // or any other extension based on your file type
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("File Downloaded successfully");
      })
      .catch((err) => {
        console.log("This is error", err);

        if (err.response.status >= 400 && err.response.status < 500) {
          toast.error(`${err.response.data.error.message}`);
        }
      });
  };

  const getCampaignList = async () => {
    const payLoad = {
      sortBy: "createdDate",
      orderBy: "desc",
      active: true,
      // flag: true,
    };

    const apiUrl = APIs.getCampaignList;

    await axios
      .post(apiUrl, payLoad, {
        headers: {
          "X-Session": getSession,
        },
      })
      .then((res) => {
        let result = res.data;
        if (Array.isArray(result.data.campaignList)) {
          setCampaignList(result.data.campaignList);
        } else {
          console.error(
            "campaignList is not an array:",
            result.data.campaignList
          );
        }
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          toast.error(`${err.response.data.error}`);
        }
      });
  };

  useEffect(() => {
    console.log("useEffect triggered");
    getApprovedTemplates();
    getCampaignList();
    getUploadStatus();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideBar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#d7d3d3",
            // p: 2,
            pt: 1,
            height: "100vh",
            // marginTop: "10px",
          }}
        >
          <Toolbar />

          <Box
            sx={{
              background: "white",
              height: "86vh",
              mt: 1,
              ml: 1.2,
              mr: 1.2,
              overflow: "auto",
            }}
          >
            {/* <div
              className=""
              style={{ textAlign: "end", marginTop: "20px" }}
              onClick={goToUploadStatus}
            >
              <Button>Go to Upload Status</Button>
            </div> */}
            <div className="bulk_message">
              {selectFile === 1 && (
                <div className="" style={{ marginTop: "30px" }}>
                  <div
                    className="selectFile"
                    style={{}}
                    onClick={handleSelectFile}
                  >
                    <div>
                      <img src={SelectBulk} alt="SelctBulkfile" />
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>
                        Select file to upload{" "}
                      </span>{" "}
                      <br />
                      <span style={{ fontSize: "1vw", color: "grey" }}>
                        Supported formats are .CSV, .XLS, .XLSX
                      </span>
                    </div>
                  </div>
                  <div className="uploadHistory">
                    <div
                      className="tableHeader"
                      style={{ marginBottom: "20px" }}
                    >
                      <div>
                        <span>Upload History</span>
                      </div>
                      <div className="filterContainer">
                        <div className="filterText">Filter</div>
                        <div className="filterIcon">
                          <IconButton onClick={handleFilterClick}>
                            <FilterListSharpIcon />
                          </IconButton>
                        </div>
                      </div>
                      <Menu
                        anchorEl={filterAnchorEl}
                        open={Boolean(filterAnchorEl)}
                        onClose={handleFilterClose}
                      >
                        <MenuItem onClick={() => handleFilterOption("date")}>
                          By Date
                        </MenuItem>
                        <MenuItem onClick={() => handleFilterOption("status")}>
                          By Status
                        </MenuItem>
                      </Menu>

                      {filterOption === "date" && (
                        <Box>
                          <TextField
                            type="date"
                            label="From Date"
                            value={startDate}
                            onChange={(e) =>
                              handleDateChange("start", e.target.value)
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TextField
                            type="date"
                            label="To Date"
                            value={endDate}
                            onChange={(e) =>
                              handleDateChange("end", e.target.value)
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                      )}

                      {filterOption === "status" && (
                        <Box>
                          <Select
                            className="filterSelect"
                            sx={{
                              height: "34px",
                              width: "100px",
                              borderRadius: "10px",
                            }}
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                          >
                            <MenuItem
                              value={"all"}
                              onClick={() => handleFilterChange("all")}
                            >
                              All
                            </MenuItem>
                            <MenuItem
                              value={"accepted"}
                              onClick={() => handleFilterChange("accepted")}
                            >
                              Accepted
                            </MenuItem>
                            <MenuItem
                              value={"inprogress"}
                              onClick={() => handleFilterChange("inprogress")}
                            >
                              Inprogress
                            </MenuItem>
                            <MenuItem
                              value={"rejected"}
                              onClick={() => handleFilterChange("rejected")}
                            >
                              Rejected
                            </MenuItem>
                          </Select>
                          <Button onClick={fetchFilteredStatus}>
                            Apply Status Filter
                          </Button>
                        </Box>
                      )}
                    </div>
                    <div>
                      <TableContainer
                        className="hidden-scrollbar"
                        component={Paper}
                        sx={{
                          // minHeight: 225,
                          height: "fit-content",
                          overflow: "auto",
                          borderRadius: "10px",
                          maxWidth: "100%",
                          // marginLeft: "23px",
                        }}
                      >
                        <Table
                          sx={{ backgroundColor: "#f0f0f0" }}
                          aria-label="simple table"
                          stickyHeader
                        >
                          <TableHead
                            className="tableHead"
                            style={{ fontWeight: "bold" }}
                          >
                            <TableRow className="tableCell">
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "blue",
                                  color: "white",
                                }}
                              >
                                Submit Time
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "blue",
                                  color: "white",
                                }}
                              >
                                Template Name
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "blue",
                                  color: "white",
                                }}
                              >
                                Status
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "blue",
                                  color: "white",
                                }}
                              >
                                Accepted
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "blue",
                                  color: "white",
                                }}
                              >
                                Rejected
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {uploadStatus?.map((item, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                    overflow: "auto",
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {moment
                                    .utc(item.createdTime)
                                    .local()
                                    .format("MMM Do YYYY, h:mm:ss A")}
                                </TableCell>
                                <TableCell align="left">
                                  {item.templateName}
                                </TableCell>
                                <TableCell align="left">
                                  <div
                                    className={`status ${
                                      item.status === "REJECTED"
                                        ? "rejected"
                                        : ""
                                    }`}
                                  >
                                    {item.status}
                                    {item.status === "REJECTED" && (
                                      <Tooltip
                                        title={
                                          item.comments ||
                                          "No Rejected Description Available"
                                        }
                                      >
                                        <InfoIcon
                                          style={{
                                            height: "20px",
                                            marginLeft: "8px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      // background: "green",
                                      borderRadius: "10px",
                                      boxShadow: "0px 2px 5px 0px",
                                    },
                                  }}
                                  align="center"
                                  onClick={() =>
                                    handleGetStatusFile("Success", item.id)
                                  }
                                >
                                  {item.successCount}
                                  <div>
                                    <DownloadIcon />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      // background: "red",
                                      borderRadius: "10px",
                                      boxShadow: "0px 2px 5px 0px",
                                    },
                                  }}
                                  align="center"
                                  onClick={() =>
                                    handleGetStatusFile("error", item.id)
                                  }
                                >
                                  {item.errorCount}
                                  <div>
                                    <DownloadIcon />
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className="pagination" style={{ marginTop: "20px" }}>
                        <Pagination
                          pageCount={pageCount}
                          handlePageClick={handlePageClick}
                          currentPage={currentPage}
                        />
                      </div>

                      <Dialog
                        disableEnforceFocus
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => handleCloseAlert(false)}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogTitle>
                          Success/Error file Download alert
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            Success/Error Alert
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => handleCloseAlert(false)}>
                            Cancle
                          </Button>
                          <Button onClick={() => handleCloseAlert(true)}>
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                </div>
              )}

              {selectFile === 2 && (
                <div className="selectFile1" style={{ marginTop: "50px" }}>
                  <div className="header">
                    <div
                      className="text"
                      style={{
                        marginTop: "15px",
                        position: "relative",
                        fontWeight: "bold",
                      }}
                    >
                      <span>Select your File</span>
                    </div>
                    <div
                      className="closeButton"
                      style={{ marginTop: "15px", cursor: "pointer" }}
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                  <hr style={{ marginTop: "20px" }} />

                  <div className="bodyContent">
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "70px",
                        marginBottom: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      <label>Template</label>
                    </div>
                    <div style={{ marginLeft: "70px", marginBottom: "10px" }}>
                      <Select
                        sx={{
                          width: "87%",
                        }}
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                      >
                        {approvedTemplates.length === 0 && (
                          <MenuItem disabled>No templates available</MenuItem>
                        )}
                        {approvedTemplates.map((template) => (
                          <MenuItem
                            key={template.id}
                            value={template.templatename}
                          >
                            {template.templatename}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginLeft: "70px",
                        marginBottom: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      <span>Select Campaign</span>
                    </div>
                    <div style={{ marginLeft: "70px", marginBottom: "10px" }}>
                      <Select
                        sx={{
                          width: "87%",
                        }}
                        value={selectedCampaign ? selectedCampaign.id : ""}
                        onChange={handleCampaignChange}
                      >
                        {campaignList.length === 0 && (
                          <MenuItem disabled>No Campaign available</MenuItem>
                        )}
                        {campaignList.map((campaign) => (
                          <MenuItem
                            key={campaign.id}
                            value={campaign.id} // Changed from campaign.campaignName to campaign.id
                          >
                            {campaign.campaignName}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div
                      className=""
                      style={{ marginBottom: "20px" }}
                      onClick={handleDivClick}
                    >
                      <div
                        style={{
                          marginTop: "20px",
                          marginLeft: "70px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        <label>File Upload</label>
                      </div>
                      <div
                        className="selectFile"
                        style={{
                          marginLeft: "70px",
                          marginTop: "5px",
                          width: "80%",
                        }}
                      >
                        <div>
                          <img src={SelectBulk} alt="SelctBulkfile" />
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Select file to upload
                          </span>
                          <br />
                          <span style={{ fontSize: "1vw", color: "grey" }}>
                            Supported formats are .CSV, .XLS, .XLSX
                          </span>
                        </div>
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".csv"
                        onChange={handleFileChange}
                      />
                      {fileName && (
                        <p style={{ color: "grey", marginLeft: "70px" }}>
                          {fileName}
                        </p>
                      )}
                      {showSuccess && (
                        <div style={{ color: "green", marginLeft: "70px" }}>
                          {errorMsg}
                        </div>
                      )}
                      {showError && (
                        <div style={{ color: "red", marginLeft: "70px" }}>
                          {errorMsg}
                        </div>
                      )}
                    </div>
                    <div
                      className="sendButton"
                      style={{ textAlign: "end", marginRight: "85px" }}
                    >
                      <Button
                        variant="outlined"
                        style={{ marginBottom: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                        variant="contained"
                        onClick={handleSend}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {selectFile === 3 && (
                <div className="getStatus">
                  <div className="header">
                    <div
                      className="text"
                      style={{
                        marginTop: "15px",
                        position: "relative",
                        fontWeight: "bold",
                      }}
                    >
                      <span>Bulk Message</span>
                    </div>
                    <div
                      className="closeButton"
                      style={{ marginTop: "15px", cursor: "pointer" }}
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                  <hr style={{ marginTop: "20px", marginBottom: "10px" }} />
                  {/* <div
                    className="selectFile"
                    style={{
                      marginLeft: "110px",
                      marginTop: "5px",
                      width: "70%",
                    }}
                    onClick={handleDivClick}
                  >
                    <div>
                      <img src={SelectBulk} alt="SelctBulkfile" />
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>
                        Select file to upload
                      </span>
                      <br />
                      <span style={{ fontSize: "1vw", color: "grey" }}>
                        Supported formats are .CSV, .XLS, .XLSX
                      </span>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept=".csv"
                      onChange={handleFileChange}
                    />
                  </div>
                  {fileName && (
                    <p style={{ color: "grey", marginLeft: "70px" }}>
                      {fileName}
                    </p>
                  )}
                  {showSuccess && (
                    <div style={{ color: "green", marginLeft: "70px" }}>
                      {errorMsg}
                    </div>
                  )}
                  {showError && (
                    <div style={{ color: "red", marginLeft: "70px" }}>
                      {errorMsg}
                    </div>
                  )} */}
                </div>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BulkMessage;
