import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../SalesPesonChattingDetails/salesPersonChatting.css";
import { APIs } from "../../Shared/Config/config";
import axios from "axios";
import { toast } from "react-toastify";
import LinearProgress from "@mui/material/LinearProgress";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import EmojiPicker from "emoji-picker-react";
// import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { IoMdLogOut } from "react-icons/io";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";

const SalesPersonChatting = () => {
  const navigate = useNavigate();
  const getSession = sessionStorage.getItem("X-Session");
  const [chatData, setChatData] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [confirmLogOut, setConfirmLogOut] = useState(false);

  useEffect(() => {
    const getChatData = async () => {
      await fetchChatData();
    };
    getChatData();
  }, []);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  // Helper function to clean the message
  const cleanMessage = (message = "") => {
    try {
      const parsedMessage = JSON.parse(message);
      return parsedMessage.body || ""; // Extract and return the `body` content
    } catch (error) {
      // In case of any error during JSON parsing, return the original message
      return message;
    }
  };

  const cleanCustomerMessage = (message = "") => {
    if (message === null || message === undefined) {
      return ""; // Or handle it as you see fit
    }
    return message.replace("{body=", "").replace("}", "");
  };

  const truncateMessage = (message, wordsLimit) => {
    if (!message) return "";
    const words = message.split(" ");
    if (words.length > wordsLimit) {
      return words.slice(0, wordsLimit).join(" ") + "...";
    }
    return message;
  };

  const handleEmojiClick = (emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleLogout = () => {
    setConfirmLogOut(false);
    navigate("/");
  };

  const handleLogOutOpen = () => {
    setConfirmLogOut(true);
  };

  const handleLogoutClose = () => {
    setConfirmLogOut(false);
  };

  const fetchChatData = async () => {
    const apiurl = APIs.getChatData;

    try {
      const res = await axios.post(
        apiurl,
        {},
        {
          headers: {
            "X-session": getSession,
          },
        }
      );

      const response = res.data;
      console.log("Raw API Response:", response);

      if (response.data && response.data.chatData) {
        const processedChatData = response.data.chatData.map((chat) => ({
          ...chat,
          chatData: chat.chatData.map((message) => ({
            ...message,
            sender: message.salesPersonMessage ? "salesperson" : "customer",
            customerMessage: cleanMessage(message.customerMessage),
            salesPersonMessage: cleanMessage(message.salesPersonMessage),
          })),
        }));
        console.log("Processed Chat Data:", processedChatData);
        setChatData(processedChatData);
      } else {
        console.log("No chatData found in the response.");
        toast.error("No chat data found.");
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 500
      ) {
        toast.error(`${err.response.data.error.message}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleSendMessage = async () => {
    if (selectedChat && newMessage.trim() !== "") {
      const payLoad = {
        chatId: selectedChat.id,
        messageType: "text",
        message: JSON.stringify({ body: newMessage }), // Raw message sent to backend
      };

      const apiurl = APIs.salesPersonChatting;

      try {
        const res = await axios.post(apiurl, payLoad, {
          headers: {
            "X-Session": getSession,
          },
        });

        const response = res.data;

        if (response && response.data && response.data.message === "success") {
          const updatedChatData = [
            ...selectedChat.chatData,
            {
              sender: "salesperson",
              salesPersonMessage: newMessage, // Store the clean message content directly
              timestamp: new Date(),
            },
          ];

          setSelectedChat({
            ...selectedChat,
            chatData: updatedChatData,
          });

          setChatData((prevChats) =>
            prevChats.map((chat) =>
              chat.id === selectedChat.id
                ? { ...chat, chatData: updatedChatData }
                : chat
            )
          );

          setNewMessage(""); // Clear the input field after sending
        } else {
          toast.error("Failed to send the message.");
        }
      } catch (err) {
        if (
          err.response &&
          err.response.status >= 400 &&
          err.response.status < 500
        ) {
          toast.error(`${err.response.data.error}`);
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "#d7d3d3",
          overflow: "hidden",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            background: "white",
            height: "100vh",
          }}
        >
          <div className="main-container">
            <div className="chat-container" style={{ width: "30%" }}>
              <div className="searchUser">
                <TextField
                  placeholder="Search by name"
                  InputProps={{
                    className: "searchInput",
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {chatData.length > 0 ? (
                chatData.map((chat, index) => (
                  <div
                    key={index}
                    className="chat-content"
                    onClick={() => handleChatClick(chat)}
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <p>
                      <strong style={{ fontSize: "15px" }}>
                        {chat.customerName}
                      </strong>
                    </p>
                    <p style={{ fontSize: "11px", color: "grey" }}>
                      {chat.chatData && chat.chatData.length > 0
                        ? truncateMessage(
                            cleanCustomerMessage(
                              chat.chatData[chat.chatData.length - 1]
                                .customerMessage
                            ),
                            5
                          )
                        : "No messages available"}
                    </p>
                  </div>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>
                  Loading <LinearProgress />
                </p>
              )}
              <div className="chat-footer" onClick={handleLogOutOpen}>
                Logout <IoMdLogOut style={{ marginLeft: "8px"  }} />
              </div>
            </div>

            <div className="chat-details">
              {selectedChat ? (
                selectedChat.chatData && selectedChat.chatData.length > 0 ? (
                  <>
                    {/* Sticky Header */}
                    <div className="chat-header">
                      <div className="customer-name">
                        {selectedChat.customerName}
                      </div>
                      <div className="salesPerson-name">
                        {selectedChat.salesPersonName}
                      </div>
                      <div className="salesPerson-status">
                        <Select
                          className="salesPerson-option"
                          sx={{
                            width: "150px",
                            height: "40px",
                            marginRight: "10px",
                          }}
                        >
                          <MenuItem>Close Chat</MenuItem>
                        </Select>
                      </div>
                    </div>

                    {/* Messages Section */}
                    <div
                      className="messages-section"
                      style={{
                        overflowY: "auto",
                        height: "calc(100vh - 100px)",
                      }}
                    >
                      {selectedChat.chatData.map((message, idx) => (
                        <div
                          key={idx}
                          className={`message-container ${
                            message.sender === "salesperson"
                              ? "salesperson"
                              : "customer"
                          }`}
                        >
                          <div
                            className={`message ${
                              message.sender === "salesperson"
                                ? "salesperson-message"
                                : "customer-message"
                            }`}
                          >
                            <p>
                              {message.sender === "salesperson"
                                ? message.salesPersonMessage // Display the clean message content
                                : cleanCustomerMessage(message.customerMessage)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <p>No messages available</p>
                )
              ) : (
                <p>Select a user to view messages</p>
              )}
              {selectedChat && (
                <div className="chat-input-container">
                  <TextField
                    fullWidth
                    placeholder="Type your message here..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSendMessage();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            color="primary"
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          >
                            <EmojiEmotionsIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            onClick={handleSendMessage}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {showEmojiPicker && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "50px",
                        left: "0",
                        zIndex: "1000",
                      }}
                    >
                      <EmojiPicker onEmojiClick={handleEmojiClick} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <Dialog
            className="dialogModal"
            disableEnforceFocus
            open={confirmLogOut}
            onClose={handleLogoutClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Logout</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to logout?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLogoutClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleLogout} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};

export default SalesPersonChatting;
