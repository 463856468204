import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Grid, TextField, Button, Typography, InputAdornment } from "@mui/material";
import axios from "axios";
import { APIs } from "../../Shared/Config/config";
import Echocast_logo from "../../Assets/Images/Echocast_logo.png";
import Echocast_left_image from "../../Assets/Images/Echocast_Left_image.jpg";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../Login/login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Bars } from "react-loader-spinner";

const Login = () => {
  const location = useLocation();
  const mobileNumber = location.state?.mobile;

  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [mobErrMsg, setMobErrMsg] = useState("");
  const [otpErrMsg, setOtpErrMsg] = useState("");
  const [error, setError] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [countryCode, setCountryCode] = useState("in");
  const [dialCode, setDialCode] = useState("");
  const [loading, setLoading] = useState(false); // New state for loading

  const handleOnChange = (phone, mobileData) => {
    const cd = mobileData.dialCode.length;
    const number = phone.slice(cd);
    setMobile(number);
    setDialCode(mobileData.dialCode);
    setCountryCode(mobileData.countryCode);
  };

  const handleGetOtp = async (event, reOtp) => {
    event.preventDefault();

    setMobErrMsg("");

    if (!mobile) {
      setMobErrMsg("Mobile number is required");
      return;
    }

    setLoading(true); // Set loading to true when API call starts

    const requestData = {
      countryCode: `+${dialCode}`,
      mobileNumber: mobile,
      userType: 2,
    };

    await axios
      .post(APIs.getOtp, requestData)
      .then((res) => {
        let result = res.data.data;
        console.log(result);
        sessionStorage.setItem("X-Session", result.session);
        setOtpSent(true);
        navigate("", { state: { mobileNumber: mobile } });
        toast.success("OTP has been sent successfully!");
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log(err);
          setError("", mobile);
          toast.error(`${err.response.data.error.message}`);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call completes
      });
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setOtpErrMsg("");

    if (!otp) {
      setOtpErrMsg("OTP is required");
      return;
    }

    setLoading(true); // Set loading to true when API call starts

    const requestData = {
      countryCode: `+${dialCode}`,
      mobileNumber: mobile,
      otp: otp,
      userType: 2,
    };

    await axios
      .post(APIs.verifyOtp, requestData)
      .then((res) => {
        let result = res.data.data;
        console.log(result);
        sessionStorage.setItem("X-Session", result.session);
        navigate("/SalesPersonChatingDetails");
        toast.success("LoggedIn successfully!");
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log(err);
          toast.error(`${err.response.data.error.message}`);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call completes
      });
  };

  return (
    <div className="container">
      <Container
        maxWidth="xl"
        style={{
          backgroundColor: "grey",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px",
          margin: "0px",
          overflow: "hidden",
        }}
      >
        <Grid container>
          {/* Image */}
          <Grid item xs={12} sm={6}>
            <img
              src={Echocast_left_image}
              alt="Login"
              style={{ width: "100%", height: "100vh" }}
            />
          </Grid>
          {/* Login Form */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              backgroundColor: "white",
              padding: "70px",
              borderRadius: "5px",
            }}
          >
            <Grid item xs={12} m={6}>
              <img
                src={Echocast_logo}
                alt="Logo"
                style={{ width: "50%", display: "block", margin: "auto" }}
              />
            </Grid>
            <div style={{ textAlign: "center", marginTop: "-40px" }}>
              <Typography variant="h6" gutterBottom>
                Sign in to Echocast
              </Typography>
              <Typography variant="body2" style={{ color: "grey" }}>
                Sign in using your Echocast account
              </Typography>
            </div>

            <form style={{ marginTop: "50px" }}>
              <div className="mobile">
                <div className="phone">
                  <label>Enter Mobile Number</label>
                  {otpSent && (
                    <Button
                      style={{ fontSize: "10px", marginLeft: "10px" }}
                      onClick={() => setOtpSent(false)}
                    >
                      Edit Number
                    </Button>
                  )}

                  <div className="loginMobile" style={{ display: "flex" }}>
                    <PhoneInput
                      type="text"
                      placeholder="Enter Mobile Number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      size="small"
                      style={{
                        width: "65%",
                        margin: "auto",
                        borderRadius: "5px",
                        background: otpSent ? "lightgrey" : "none",
                      }}
                      disabled={otpSent}
                      inputProps={{ maxLength: 19, pattern: "[0-9]*" }}
                      country={countryCode}
                      onChange={(value, mobileData) =>
                        handleOnChange(value, mobileData)
                      }
                      value={mobileNumber}
                    />
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "0.8em",
                    color: "red",
                    width: "65%",
                    display: "block",
                    margin: "auto",
                    marginBottom: "18px",
                  }}
                >
                  {mobErrMsg}
                </div>
              </div>

              {otpSent ? (
                <div className="enterOtp">
                  <label>Enter OTP</label>
                  <TextField
                    type="text"
                    placeholder="Enter the OTP"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    style={{
                      width: "65%",
                      display: "block",
                      margin: "auto",
                      borderRadius: "5px",
                    }}
                    inputProps={{ maxLength: 6, pattern: "[0-9]*" }}
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            variant="body2"
                            sx={{
                              cursor: "pointer",
                              color: "blue",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                            onClick={handleGetOtp}
                          >
                            Resend OTP
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      fontSize: "0.8em",
                      color: "red",
                      width: "65%",
                      display: "block",
                      margin: "auto",
                      marginBottom: "18px",
                    }}
                  >
                    {otpErrMsg}
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                      width: "65%",
                      display: "block",
                      margin: "auto",
                      backgroundColor: "#5c77f8",
                    }}
                    onClick={handleVerifyOtp}
                    disabled={loading} // Disable button while loading
                  >
                    {/* {loading ? "Verifying..." : "Verify & Sign In"} */}

                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ color: "white" }}>Verifying</span>
                        <Bars
                          height="20"
                          width="20"
                          color="#fff" // Adjust the color to match the button's text color
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <span style={{ marginRight: "8px", color: "white" }}>
                        Verify & Sign In
                      </span>
                    )}
                  </Button>
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{
                    width: "65%",
                    display: "block",
                    margin: "auto",
                    backgroundColor: "#5c77f8",
                  }}
                  onClick={handleGetOtp}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ marginLeft: "8px", color: "white" }}>
                        Please Wait
                      </span>
                      <Bars
                        height="20"
                        width="20"
                        color="#fff" // Adjust the color to match the button's text color
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Get OTP"
                  )}
                </Button>
              )}
            </form>

            {/* Signup button */}
            {/* <div style={{ textAlign: "center", marginTop: "16px" }}>
              <Link
                to="/register"
                style={{ color: "#5c77f8", textDecoration: "none" }}
              >
                Dont have an account? Sign up!
              </Link>
            </div> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
