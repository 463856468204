import {
  Box,
  TextField,
  Toolbar,
  IconButton,
  Menu,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../SideBar/sidebar";
import "../Campaign/campaign.css";
import {
  Button,
  Dialog,
  DialogContentText,
  MenuItem,
  Select,
} from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { APIs } from "../../Shared/Config/config";
import axios from "axios";
import { toast } from "react-toastify";
import Pagination from "../../Shared/Pagination/pagination";
import "../../Shared/Pagination/pagination.css";
import moment from "moment";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

function Campaign() {
  const getSession = sessionStorage.getItem("X-Session");
  let [open, setOpen] = useState(false);
  let [campaignName, setCampaignName] = useState("");
  let [campaignStartDate, setCampaignStartDate] = useState(null);
  let [campaignEndDate, setCampaignEndDate] = useState(null);
  let [errors, setErrors] = useState("");
  let [campaignList, setCampaignList] = useState([]);
  let [currentPage, setCurrentPage] = useState(0);
  let [pageCount, setPageCount] = useState(0);
  let [selectedCampaign, setSelectedCampaign] = useState(null);
  let [deleteCampaign, setDeleteCampaign] = useState(null);
  let [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [dialogCampaignName, setDialogCampaignName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  let [representative, setrepresentative] = useState([]);
  const [selectedExecutives, setSelectedExecutives] = useState([]);
  const [checkedExecutives, setCheckedExecutives] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [executiveToDisable, setExecutiveToDisable] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterOption, setFilterOption] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");

  useEffect(() => {
    getCampaignList(0);
  }, []);

  useEffect(() => {
    if (selectedCampaign) {
      setCampaignName(selectedCampaign.campaignName);
      setCampaignStartDate(selectedCampaign.startDate);
      setCampaignEndDate(selectedCampaign.endDate);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCampaign) {
      const savedExecutives =
        JSON.parse(
          localStorage.getItem(`selectedExecutives_${selectedCampaign.id}`)
        ) || [];
      const updatedCheckedExecutives = {};

      savedExecutives.forEach((id) => {
        updatedCheckedExecutives[id] = true;
      });

      setCheckedExecutives(updatedCheckedExecutives);

      const updatedSelectedExecutives = representative.filter((exec) =>
        savedExecutives.includes(exec.id)
      );
      setSelectedExecutives(updatedSelectedExecutives);
    }
  }, [selectedCampaign, representative]);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterOption = (option) => {
    setFilterOption(option);
    setFilterAnchorEl(null); // Close the menu on option selection
  };

  const handleDateChange = (type, value) => {
    if (type === "start") {
      setStartDate(value);
    } else if (type === "end") {
      setEndDate(value);
    }
  };

  const filteredCampaignList = campaignList.filter((list) => {
    const startDateFilter = new Date(startDate);
    const endDateFilter = new Date(endDate);
    const listStartDate = new Date(list.startDate);

    return (
      (!startDate || listStartDate >= startDateFilter) &&
      (!endDate || listStartDate <= endDateFilter)
    );
  });

  const handleOpenDialog = () => {
    setSelectedCampaign(null);
    setCampaignName("");
    setCampaignStartDate("");
    setCampaignEndDate("");
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedCampaign(null);
    setOpen(false);
  };

  const handleMenuClick = (event, campaign) => {
    setAnchorEl(event.currentTarget);
    setSelectedCampaign(campaign);
  };

  const handleDeleteClick = (campaign) => {
    setDeleteCampaign(campaign);
    console.log(deleteCampaign);
    setConfirmDeleteOpen(true);
    // handleDisableCampaign(campaignId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCampaign(null);
  };

  const handleDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setDeleteCampaign(null);
  };

  const handleEditCampaign = (campaign) => {
    console.log("Selected Campaign for Edit:", campaign);
    setSelectedCampaign(campaign);
    setOpen(true);
  };

  const handleCampaignCardClick = async (campaign) => {
    setSelectedCampaign(campaign);
    setDialogCampaignName(campaign.campaignName);
    await handleGetRepresentatives();
    setDialogOpen(true);

    // Load previously selected executives for the current campaign from localStorage
    const storedExecutives = localStorage.getItem(
      `selectedExecutives_${campaign.id}`
    );
    if (storedExecutives) {
      const parsedExecutives = JSON.parse(storedExecutives);
      const newCheckedExecutives = {};
      parsedExecutives.forEach((id) => {
        newCheckedExecutives[id] = true;
      });
      setCheckedExecutives(newCheckedExecutives);
      setSelectedExecutives(
        representative.filter((exec) => parsedExecutives.includes(exec.id))
      );
    } else {
      setCheckedExecutives({});
      setSelectedExecutives([]);
    }
  };

  const handleCheckboxChange = (executive) => {
    const isChecked = !checkedExecutives[executive.id];
    const updatedCheckedExecutives = {
      ...checkedExecutives,
      [executive.id]: isChecked,
    };

    setCheckedExecutives(updatedCheckedExecutives);

    const updatedSelectedExecutives = representative.filter(
      (exec) => updatedCheckedExecutives[exec.id]
    );

    setSelectedExecutives(updatedSelectedExecutives);

    // Store the updated checked executives in localStorage
    localStorage.setItem(
      `selectedExecutives_${selectedCampaign.id}`,
      JSON.stringify(updatedSelectedExecutives.map((exec) => exec.id))
    );

    if (!isChecked) {
      setExecutiveToDisable(executive);
      setConfirmationOpen(true);
    }
  };

  const handleConfirmDisable = async () => {
    // Close confirmation dialog
    setConfirmationOpen(false);

    // Disable executive and update UI
    const updatedCheckedExecutives = {
      ...checkedExecutives,
      [executiveToDisable.id]: false,
    };
    setCheckedExecutives(updatedCheckedExecutives);

    const updatedSelectedExecutives = selectedExecutives.filter(
      (e) => e.id !== executiveToDisable.id
    );
    setSelectedExecutives(updatedSelectedExecutives);

    // Update local storage
    localStorage.setItem(
      `selectedExecutives_${selectedCampaign.id}`,
      JSON.stringify(updatedSelectedExecutives.map((exec) => exec.id))
    );

    // Call API to update enabled status (false) for this executive
    const apiPayload = {
      campaignId: selectedCampaign.id,
      executiveList: [
        {
          executiveId: executiveToDisable.id,
          enabled: false,
        },
      ],
    };

    try {
      const response = await axios.post(APIs.salesCampaignMapping, apiPayload, {
        headers: {
          "X-Session": getSession,
        },
      });

      if (response.status === 200) {
        toast.success(`${executiveToDisable.name} disabled successfully.`);
      } else {
        toast.error("Failed to disable executive.");
      }
    } catch (error) {
      console.error("Error disabling executive:", error);
      toast.error("An error occurred while disabling executive.");
    }
  };

  const handleCancelDisable = () => {
    // Close confirmation dialog
    setConfirmationOpen(false);
    // Reset checkbox to checked state
    setCheckedExecutives((prevCheckedExecutives) => ({
      ...prevCheckedExecutives,
      [executiveToDisable.id]: true,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "campaignName":
        setCampaignName(value);
        break;

      case "startDate":
        setCampaignStartDate(value);
        break;

      case "endDate":
        setCampaignEndDate(value);
        break;

      default:
        break;
    }
  };

  const validate = () => {
    let tempErrors = {};

    tempErrors.campaignName = campaignName ? "" : "Campaign Name is required";
    tempErrors.startDate = campaignStartDate
      ? ""
      : "Campaign StartDate is required";
    tempErrors.endDate = campaignEndDate ? "" : "Campaign end date is required";

    setErrors(tempErrors);
    return Object.values(tempErrors).every((X) => X === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      let payLoad = {
        campaignName: campaignName,
        startDate: campaignStartDate,
        endDate: campaignEndDate,
      };

      if (selectedCampaign) {
        let apiUrl = `${APIs.updateCampaign}/${selectedCampaign.id}`;

        await axios
          .put(apiUrl, payLoad, {
            headers: {
              "X-Session": getSession,
            },
          })
          .then((res) => {
            let result = res.data;
            console.log("Result", result);
            toast.success(`${result.data.message}`);
            getCampaignList();
            setCampaignName("");
            setCampaignStartDate("");
            setCampaignEndDate("");
          })
          .catch((err) => {
            if (err.response.status >= 400 && err.response.status < 500) {
              console.log(err);
              toast.error(`${err.response.data.error}`);
            }
          });
      } else {
        let apiUrl = APIs.createCampaign;

        await axios
          .post(apiUrl, payLoad, {
            headers: {
              "X-Session": getSession,
            },
          })
          .then((res) => {
            let result = res.data;
            console.log("Result", result);
            toast.success(`${campaignName + " " + result.data.message}`);
            getCampaignList();
            setCampaignName("");
            setCampaignStartDate("");
            setCampaignEndDate("");
            getCampaignList();
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status >= 400 &&
              err.response.status < 500
            ) {
              console.log(err);
              toast.error(`${err.response.data.error}`);
            }
          });
      }
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data);
    console.log("selectedPage", data);
    getCampaignList(data);
  };

  const getCampaignList = async (e) => {
    setCurrentPage(e);

    let limit = 8;
    let offset = e * limit;

    let payLoad = {
      limit: limit,
      offset: offset,
      sortBy: "createdDate",
      orderBy: "desc",
      // active: true,
    };

    let apiUrl = APIs.getCampaignList;

    await axios
      .post(apiUrl, payLoad, {
        headers: {
          "X-Session": getSession,
        },
      })
      .then((res) => {
        let result = res.data;
        setCampaignList(result.data.campaignList);
        setPageCount(Math.ceil(result.data.count / limit));
        console.log("Result", result);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log(err);
          toast.error(`${err.response.data.error}`);
        }
      });
  };

  const handleDisableCampaign = async (campaign) => {
    const newStatus = campaign.active ? "disable" : "enable";
    const apiurl = `${APIs.disableCampaign}/${campaign.id}`;

    try {
      const res = await axios.put(
        apiurl,
        {},
        {
          headers: {
            "X-Session": getSession,
          },
        }
      );

      const message =
        res.data.data.message || `Campaign ${newStatus}d successfully`;
      toast.success(message);
      handleDeleteClose();
      handleMenuClose();
      getCampaignList();
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status < 500
      ) {
        toast.error(`${err.response.data.error}`);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleGetRepresentatives = async (e) => {
    setCurrentPage(e);

    let payLoad = {
      sortBy: "createdDate",
      orderBy: "desc",
    };

    let apiurl = APIs.getRepresentative;

    await axios
      .post(apiurl, payLoad, {
        headers: {
          "X-session": getSession,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setrepresentative(result.executiveList);
        console.log("Reuslt", result);
        // toast.success(`${result.data.message}`);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          console.log("Error", err);
          toast.error(`${err.data.error}`);
        }
      });
  };

  const handleSalesCampaignMapping = async () => {
    if (!selectedCampaign) {
      toast.error("No campaign selected.");
      return;
    }

    if (selectedExecutives.length === 0) {
      toast.error("Please select at least one executive.");
      return;
    }

    // Filter out executives that are unchecked (enabled = false)
    const mappedExecutives = selectedExecutives
      .filter((exec) => exec.enabled !== false) // Filter out executives with enabled as false
      .map((exec) => ({
        executiveId: exec.id,
        enabled: exec.enabled || true, // Default to true if enabled is not explicitly set
      }));

    const payload = {
      campaignId: selectedCampaign.id,
      executiveList: mappedExecutives,
    };

    try {
      const response = await axios.post(APIs.salesCampaignMapping, payload, {
        headers: {
          "X-Session": getSession,
        },
      });

      if (response.status === 200) {
        toast.success("Sales executives mapped to campaign successfully.");
        setDialogOpen(false);
      } else {
        toast.error("Failed to map sales executives to campaign.");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error(
          "Conflict: Data already exists or there is a concurrency issue."
        );
      } else {
        console.error("Error mapping sales executives to campaign:", error);
        toast.error("An error occurred while mapping sales executives.");
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "#d7d3d3",
          // p: 2,
          pt: 1,
          overflow: "hidden",
          height: "100vh",
          // marginTop: "10px",
        }}
      >
        <Toolbar />
        <Box
          sx={{
            background: "white",
            height: "85vh",
            mt: 1.9,
            ml: 1.2,
            mr: 1.2,
          }}
        >
          <div className="salesCampaign">
            <div className="content-Header">
              <div>
                <h3
                  style={{
                    marginLeft: "18px",
                    // paddingTop: "10px",
                    color: "#01017e",
                  }}
                >
                  Campaign
                </h3>
              </div>
              <div className="button-Container">
                <Button
                  style={{
                    marginTop: "15px",
                    backgroundColor: "orange",
                    borderRadius: "8px",
                    color: "white",
                    height: "30px",
                  }}
                  variant="contained"
                  onClick={handleOpenDialog}
                >
                  Create Campaign
                </Button>
              </div>
            </div>
            <hr color="lightgrey" />
            <div className="content-Container">
              <div className="left-Content">
                <div className="filterCampaign">
                  <div className="filterContainer">
                    <div className="filterText">Filter</div>
                    <div className="filterIcon">
                      <IconButton onClick={handleFilterClick}>
                        <FilterListSharpIcon />
                      </IconButton>
                    </div>
                  </div>
                  <Menu
                    anchorEl={filterAnchorEl}
                    open={Boolean(filterAnchorEl)}
                    onClose={handleFilterClose}
                  >
                    <MenuItem onClick={() => handleFilterOption("date")}>
                      By Date
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterOption("status")}>
                      By Status
                    </MenuItem>
                  </Menu>
                  {filterOption === "date" && (
                    <Box>
                      <TextField
                        type="date"
                        label="From Date"
                        value={startDate}
                        onChange={(e) =>
                          handleDateChange("start", e.target.value)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        type="date"
                        label="To Date"
                        value={endDate}
                        onChange={(e) =>
                          handleDateChange("end", e.target.value)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  )}

                  {filterOption === "status" && (
                    <Box>
                      <Select
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                      </Select>
                    </Box>
                  )}
                </div>

                <div className="campaign-Card">
                  {filteredCampaignList?.map(
                    (list, index) => (
                      console.log("List", campaignList),
                      (
                        <div key={index} className="campaign-item">
                          <div className="controlCampaign">
                            <div
                              className={`status ${
                                list.active ? "active" : "inactive"
                              }`}
                            >
                              {list.active ? "Active" : "Inactive"}
                            </div>
                            <div className="dotIcon">
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleMenuClick(e, list)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={openMenu}
                                onClose={handleMenuClose}
                              >
                                <MenuItem
                                  onClick={() =>
                                    handleEditCampaign(selectedCampaign)
                                  }
                                >
                                  Edit Campaign
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleDeleteClick(list)}
                                >
                                  Update Campaign
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                          <div className="name"> {list.campaignName}</div>
                          <div className="date">
                            Start Date:{" "}
                            {moment(list.startDate).format("Do MMM YYYY")}
                          </div>
                          <div className="date">
                            End Date:{" "}
                            {moment(list.endDate).format("Do MMM YYYY")}
                          </div>
                          <div onClick={() => handleCampaignCardClick(list)}>
                            <Button
                              style={{ width: "100%" }}
                              variant="outlined"
                            >
                              View Representative
                            </Button>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
                <Pagination
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                />
              </div>
              
            </div>
            <Dialog
              disableEnforceFocus
              open={open}
              PaperProps={{
                style: {
                  width: "480px",
                  height: "auto",
                  alignItems: "center",
                  borderRadius: "15px",
                  boxShadow: "0px 5px 5px 0px",
                },
              }}
            >
              <div
                style={{
                  marginLeft: "450px",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                <CloseIcon
                  onClick={handleCloseDialog}
                  sx={{
                    "&:hover": {
                      backgroundColor: "ActiveBorder",
                      borderRadius: "15px",
                      color: "red",
                    },
                  }}
                />
              </div>
              <DialogTitle style={{ marginTop: "-10px", color: "#01017e" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: "bold", color: "#01017e" }}>
                    {selectedCampaign ? "Update Campaign" : "Create Campaign"}
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="userForm">
                  <div>
                    <TextField
                      name="campaignName"
                      label="Cmpaign Name"
                      id="filled-basic"
                      variant="filled"
                      // placeholder="User Name"
                      required
                      InputProps={{
                        style: { width: "380px" },
                        inputProps: {
                          disabled: !!selectedCampaign,
                        },
                      }}
                      error={!!errors.campaignName}
                      helperText={errors.campaignName}
                      value={campaignName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      name="startDate"
                      id="filled-basic"
                      label="Start-Date"
                      type="date"
                      required
                      variant="filled"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: { width: "380px" },
                      }}
                      error={!!errors.startDate}
                      helperText={errors.startDate}
                      value={campaignStartDate}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <TextField
                      name="endDate"
                      id="filled-basic"
                      label="End-Date"
                      type="date"
                      required
                      variant="filled"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: { width: "380px" },
                      }}
                      error={!!errors.endDate}
                      helperText={errors.endDate}
                      value={campaignEndDate}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  // onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                  style={{ marginBottom: "20px", marginTop: "10px" }}
                  onClick={handleSubmit}
                >
                  {/* {selectedRep ? "UPDATE" : "SUBMIT"} */}
                  {selectedCampaign ? "Update Campaign" : "Create Campaign"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>
      </Box>
      <Dialog
        className="dialogModal"
        disableEnforceFocus
        open={confirmDeleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {selectedCampaign?.active
            ? "Disable Confirmation"
            : "Enable Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to ${
              selectedCampaign?.active ? "disable" : "enable"
            } this Campaign?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDisableCampaign(selectedCampaign)}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{dialogCampaignName}</DialogTitle>
        <DialogContent>
          <div className="executive-list-container">
            <div className="executive-list">
              <h3>All Representatives</h3>
              {representative.map((executive) => (
                <div key={executive.id} className="executive-item">
                  <span>{executive.name}</span>
                  <Checkbox
                    checked={checkedExecutives[executive.id] || false}
                    onChange={() => handleCheckboxChange(executive)}
                  />
                </div>
              ))}
            </div>
            <div className="selected-executive-list">
              <h3>Mapped Representatives</h3>
              {selectedExecutives.map((executive) => (
                <div key={executive.id} className="selected-executive-item">
                  <span>{executive.name}</span>
                </div>
              ))}
              {selectedExecutives.length > 0 && (
                <Button
                  variant="contained"
                  style={{ marginLeft: "120px", marginTop: "10px" }}
                  onClick={handleSalesCampaignMapping}
                  color="primary"
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Disable Executive"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to disable{" "}
            {executiveToDisable && executiveToDisable.name} from this campaign?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDisable} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDisable} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* </div> */}
    </Box>
  );
}

export default Campaign;
